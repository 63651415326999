import {useState} from 'react'
import Label from '../shared-components/Label'
import Select from '../shared-components/Select'
import Text from '../shared-components/Text'
import DatePicker from '../shared-components/DatePicker'
import FormActionButton from '../shared-components/FormActionButton'
import { claimValue, formPages } from '../../constants'
import ListRadioOptions from '../shared-components/ListRadioOptions'

export default function NewClaimantDetails({compensate, onNext }) {

  const [title, setTitle] = useState(compensate.title)
  const [firstName, setFirstName] = useState(compensate.firstName ?? '')
  const [surname, setSurname] = useState(compensate.surname ?? '')
  const [dob, setDob] = useState(compensate.dob ?? '')
  const [hasPreviousName, setHasPreviousName] = useState(compensate.hasPreviousName)
  const [previousName, setPreviousName] = useState(compensate.previousName ?? '')
  

  const [titleError, setTitleError] = useState(false);
  const [fnameError, setFNameError] = useState(false);
  const [lnameError, setLnameError] = useState(false);
  const [dobError, setDobError] = useState(false);
  const [hasPreviousNameError, setHasPreviousNameError] = useState(false)
  const [previousNameError, setPreviousNameError] = useState(false)
  const averageClaimValue = claimValue *2


  const titleOptions = [
    { text: "MR", value: "MR" },
    { text: "MRS", value: "MRS" },
    { text: "MS", value: "MS" },
    { text: "MISS", value: "MISS" },
    { text: "MX", value: "MX" },
];

const fname = (event) => {
  setFirstName(event)
  event === '' ? setFNameError(true) : setFNameError(false)
}

const lname = (event) => {
  setSurname(event)
  event ==='' ? setLnameError(true) : setLnameError(false)
}

const plname = (event) => {
  setPreviousName(event)
  event ==='' ? setPreviousNameError(true) : setPreviousNameError(false)
}


  return (<>
    <div className="d-flex justify-content-center align-items-center heading-icon fdc-form">
      <img src='/icons/TyreMoney.png' alt='icon1'/>
      <h2 className="title">You could be owed an average of <span className='ycm-orange'>£{averageClaimValue.toLocaleString()}*</span> based
      on 2 cars per household for mis-sold car finance. Check for FREE in 60 seconds.
      </h2>
    </div>
    <div className="fdc-form gif-input">
      <div className="row">
          <div className="col-12">
              <div className="form-block mb-3">
              <Label label={'Thank you for choosing us and we appreciate your time. Please take a moment to review your details below and update any incorrect or missing information.'}/>
              </div>
          </div>
          <div className="col-md-2">
              <div className="form-block">
                  <Select
                      label={"Title"}
                      options={titleOptions}
                      onChange={setTitle}
                      value={title}
                      id="Title"
                      placeholder={'Select'}
                  />
                  {titleError?
                      <div>
                        <span className="text-danger">
                            <p>Please select a title</p>
                        </span>
                    </div>
                    :<></>
                  }
              </div>
          </div>
      </div>
      <div className="row">
        <div className="col-md-6">
            <div className="form-block">
                <Text
                    label={"What is your first name?"}
                    onChange={(e) => fname(e.target.value)}
                    value={firstName}
                    id="First Name"
                    required={false}
                />
                {fnameError?
                      <div>
                        <span className="text-danger">
                            <p>Please enter your first name</p>
                        </span>
                    </div>
                    :<></>
                  }
            
            </div>
        </div>
        <div className="col-md-6">
            <div className="form-block">
                <Text
                    label={"What is your surname?"}
                    onChange={(e) => lname(e.target.value)}
                    value={surname}
                    id="Surname"
                    required={false}
                />

                  {lnameError?
                      <div>
                        <span className="text-danger">
                            <p>Please enter the last name</p>
                        </span>
                    </div>
                    :<></>
                  }

            </div>
        </div>
        <div className="col-12">
              <div className="form-block mb-3">
              <Label label={'Please make sure you enter your name details as they appear on your identity documents (e.g.: passport, driving licence, ID card etc)'}/>
              </div>
        </div>
        <div className='col-md-12'>
          <div className='form-block'>
            <ListRadioOptions 
              label={'Did you have a different surname when you took out your car finance?'}
              items={[{ text: "Yes", value: true }, { text: "No", value: false }]}
              onSelect={(value) => {
                setHasPreviousName(value)
                if(value === true || value === false){
                  setHasPreviousNameError(false)
                }}}
              value={hasPreviousName}
              className="d-inline-block "
              divStyle="full-width mt-0"
            />
              {hasPreviousNameError?
                <div>
                  <span className="text-danger">
                      <p>Please select an option</p>
                  </span>
                </div>
                :<></>
              }
          </div>
        </div>
        {hasPreviousName && <div className='col-md-12'> 
          <div className='form-block'>
            <Text
              label={"What is your previous surname?"}
              onChange={(e) => plname(e.target.value)}
              value={previousName}
              id="Surname"
              required={false}
            />
            {previousNameError ?
                <div>
                  <span className="text-danger">
                      <p>Please enter your previous surname</p>
                  </span>
              </div>
              :<></>
            }
          </div>
        </div>}
        <div className="col-md-12">
            <div className="form-block">
                <DatePicker
                    label={"What is your date of birth?"}
                    onChange={(formattedDate) => setDob(formattedDate)}
                    moreInfo={"We use this information to help us find your car finance agreement."}
                    value={dob}
                    id="Date of birth"
                    dateOfBirth
                    compensate={compensate}
                />
                {dobError?
                      <div>
                        <span className="text-danger">
                            <p>Please enter your date of birth</p>
                        </span>
                    </div>
                    :<></>
                  }
            </div>
        </div>  
        <div className='col-md-6 text-center mx-auto'>
          <FormActionButton 
            onNext={() => {
              if(title === '' || title === undefined){ setTitleError(true) }
              if(firstName === '' || firstName === undefined){ setFNameError(true) }
              if(surname === '' || surname === undefined){ setLnameError(true) }
              if(hasPreviousName !== true && hasPreviousName !== false){ setHasPreviousNameError(true) }
              if(hasPreviousName === true && (previousName === '' || previousName === undefined)){ setPreviousNameError(true) }
              if(dob === '' || dob === undefined){ setDobError(true) }
                                            
              if (title && firstName && surname && dob && ((hasPreviousName === true && previousName) || hasPreviousName === false)) {
                  if(dob !== undefined){
                      onNext({
                          title,
                          firstName,
                          surname,
                          dob,
                          hasPreviousName,
                          previousName,
                          claims: []
                      }, (formPages.AddressDetails))
                  
                    }
                  }
              }}
          />
          <br />
          <img className='light-bulb mb-2' src='/icons/Lightbulb-Tip.png' alt='light bulb'/><b className='tips'>Claim with confidence with Your Claim Matters</b>
          <br />
          <p className='terms'>*based on an average refund of hidden commissions of £1,100, plus 8% annual compensatory interest, and an average of two cars per household.</p>
        </div>
      </div>
    </div>
  </>)
}
