import React from 'react'

export default function Error({compensate}) {
  return (<>
    <div className='fdc-form'>
        <h2 className='title ycm-orange'>Unfortunately we've encountered a problem.</h2>
    </div>
    <div className="fdc-form gif-input">
        <div className="box-message fw-semibold theme-color-1 text-center"> 
             We're sorry, please contact our Customer Services on <a href='tel:03308280420'>0330 828 0420</a>
        </div>
    </div>
    {/*compensate?.error ?
                       <div className="fdc-form gif-input">
                       <span>Error Message:</span>
                       <div className="box-message fw-semibold theme-color-1 text-center">
                         <h6> {compensate?.error} </h6>
                       </div>
                     </div>
            :

            <></>
          */}
  </>)
}
