import React, { useState } from 'react'
import MyQRCodeComponent from '../shared-components/QrCode/MyQRCodeComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareNodes } from '@fortawesome/free-solid-svg-icons';
export default function NoClaim({compensate}) {

  const [openModal, setOpenModal] = useState(false)

  const showVoucherSection = false;

  const handleShare = async () => {
    const currentUrl = window.location.href;
    const data = `${currentUrl}?r=${compensate.uid}`;
    if(navigator.share){
      try {
        await navigator.share({
          title: 'Car Finance Claims',
          text: 'Here is the site I used to claim for my flight!',
          url: data,
        });
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else{
      navigator.clipboard.writeText(data)
      .then(() => {
        setOpenModal(true)
      })
      .catch((err) => {
        console.error('Error copying to clipboard:', err);
      });
    }
  };

  return (
    <div className='fdc-form'>
      <div className='row'>
        <div className='col-md-12'>
          <div className='box-message fw-semibold theme-color-1 text-center'>
            <p>Hi {compensate?.firstName},</p>
            <p>Unfortunately, at this stage we are unable to process your claim without any details.</p>
            <p>If you do manage to find your details confirming your lender, registration number or vehicle make information, then please go 
              to <a href='https://www.ycmcarfnanceclaims.co.uk/' target='_blank' rel='noreferrer'>
              https://www.ycmcarfnanceclaims.co.uk/</a> to start your claim.
            </p>
            {showVoucherSection && (<>
              <>
                <img src='/icons/Amazon-£50-Voucher.png' alt='voucher' style={{ maxWidth: '100%' }} />
                <p>If you would like the chance to receive a <span className='ycm-orange'>£50</span> Amazon voucher please refer us to your friends and family.</p>
                <p>Share the below QR code and for every new successful referral you could receive a voucher</p>
                <MyQRCodeComponent compensate={compensate} />
              </>
           
            <div className="py-2">
              <button
                onClick={handleShare}
                style={{ padding: '15px', backgroundColor: '#001A42', color: '#fff', borderRadius: '5px' }}>
                <div className="d-flex align-items-center">
                  <b className="pe-2">SHARE </b>
                  <FontAwesomeIcon icon={faShareNodes} size="2x" />
                </div>
              </button>
            </div> </>)}
            <p>Should you have any questions, please contact us on <a href='mailto:info@ycmcarfinanceclaims.co.uk'>info@ycmcarfinanceclaims.co.uk</a>.</p>
            <p>Thank you,<br /> From the team at Your Claim Matters.</p>
          </div>
        </div>
      </div>
    </div>
  );
}