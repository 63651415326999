import {useRef, useState} from 'react'
import Text from '../shared-components/Text'
import FormActionButton from '../shared-components/FormActionButton'
import { VALIDATE_EMAIL, formPages } from '../../constants'
import EmailInput from '../shared-components/EmailInput'
import { isValidEmail, isValidMobile } from '../../utils/utils'

export default function ContactDetails({compensate, onNext, onBack, isSubmitted}) {

  const [email, setEmail] = useState(compensate.email ?? '')
  const [mobile, setMobile] = useState(compensate.mobile ?? '')

  const [emailAddressError, setEmailAddressError] = useState(false)
  const [emailValidationError, setEmailValidationError] = useState(false)
  const [mobileNumberError, setMobileNumberError] = useState(false)
  const myRef = useRef(null)

  const executeScroll = () => myRef.current.scrollIntoView()

  const validateEmail = async() => {
    isSubmitted(true)
    try {
      const checkValidaty = await VALIDATE_EMAIL(email)
      if(checkValidaty){
        setEmailValidationError(false)
        onNext({
          email,
          mobile
        },(formPages.RegistrationDetails))
      }else{
        setEmailValidationError(true)
        executeScroll()
      }
      isSubmitted(false)
    } catch (error) {
      setEmailValidationError(true)
      executeScroll()
      console.error('Error validating email', error)
      isSubmitted(false)
    }
  }

  return (        <>
    <div className="d-flex justify-content-center align-items-center heading-icon fdc-form" ref={myRef}>
        <img src='/icons/TyrePhone.png' alt='icon3'/>
        <h2 className="title">Please provide your contact details so we can keep you updated on your claim</h2>
    </div>
    <div className="fdc-form gif-input">
      <div className="row">
        <div className="col-md-12">
            <div className="form-block">
                <EmailInput
                    label={"Email Address"}
                    onChange={(e) => {
                      setEmail(e.target.value)
                      if(isValidEmail(e.target.value)){
                        setEmailAddressError(false)
                      }
                    }}
                    value={email}
                    id={'Email Address'}
                />
                {emailAddressError || emailValidationError ?
                    <div>
                      <span className="text-danger">
                          <p>Please enter a valid email address</p>
                      </span>
                  </div>
                  :<></>
                }
            </div>
        </div>
        <div className="col-md-12">
            <div className="form-block">
                <Text
                    label={"Mobile Number"}
                    onChange={(e) => {
                      const sanitizedValue = e.target.value.replace(/\D/g, '');        
                      setMobile(sanitizedValue);
                      // Perform validation on the sanitized value
                      if(isValidMobile(sanitizedValue)){
                          setMobileNumberError(false);
                      }
                    }}
                    value={mobile}
                    id={'Mobile Number'}
                />
                {mobileNumberError?
                      <div>
                        <span className="text-danger">
                            <p>Please enter a valid mobile number</p>
                        </span>
                    </div>
                    :<></>
                  }
            </div>
        </div>
        <FormActionButton
          onNext={() => {

            if(!isValidEmail(email) || email === undefined){ setEmailAddressError(true) }
            if(!isValidMobile(mobile) && mobile?.length > 0){ setMobileNumberError(true) }

            if(isValidEmail(email) && (isValidMobile(mobile) || mobile?.length === 0)){
              validateEmail()
            }
          }}
          onBack={() => {
            onBack(formPages.AddressDetails)
          }}
        />
      </div>
    </div>
  </>)
}
