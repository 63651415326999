import {useState, useRef, useEffect} from 'react'
import SignatureCanvas from 'react-signature-canvas';
import Label from '../shared-components/Label';
import ButtonComponent from '../shared-components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormActionButton from '../shared-components/FormActionButton';
import { CREDIT_CHECK, formPages } from '../../constants';
import ListRadioOptions from '../shared-components/ListRadioOptions';
import axios from 'axios';
import Popup from "reactjs-popup";
import { getCreditCheck } from '../../containers/CarFinance/mapper';
import { Button } from 'react-bootstrap';
import MarkdownComponent from '../shared-components/MarkDown/MarkdownComponents';
import 'reactjs-popup/dist/index.css';


export default function Signature({compensate, onNext, onBack, isSubmitted}) {

  const [signature, setSignature] = useState(compensate.signature)
  const [termsConditions, setTermsConditions] = useState(compensate.termsConditions)
  const signatureRef = useRef();
  const [signaturePadWidth, setSignaturePadWidth] = useState()
  const [signatureOverlay, setSignatureOverlay] = useState(true)


  const [signError, setsignError] = useState(false);
  const [termError, setTermError] = useState(false);
  const marketingAudience = new URLSearchParams(window.location.search).get('utm_term')


  useEffect(() => {
      const updateSignaturePadWidth = () => {
        setSignaturePadWidth(signatureRef?.current?._canvas?.parentElement?.clientWidth);
      };

      // Initially set the signature pad width
      updateSignaturePadWidth();

      // Attach the event listener for window resize
      window.addEventListener('resize', updateSignaturePadWidth);

      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener('resize', updateSignaturePadWidth);
      };
  }, [])

  const clearSig1 = () =>{
    signatureRef.current.clear();
    setSignature(false)
  }

  const handleDownload = (pdfFile, fileName) => {
    // Fetch the PDF file dynamically based on the parameter
    fetch(pdfFile)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a URL for the Blob object
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);

        // Append the link to the body
        document.body.appendChild(link);

        // Simulate a click to trigger the download
        link.click();

        // Remove the link from the document
        link.parentNode.removeChild(link);
      })
      .catch((err) => console.error('Error downloading the file:', err));
  };
  const termsAndConditions = (text) =>{
    return  <Popup trigger={<button className='sign-up-terms'>{text}</button>} modal>
              {close =>(
              <div style={{ height: '500px', overflowY: 'auto'}}>
                <Button label='X' style={{position: 'absolute', right:'0', top: '0', borderRadius: '0', backgroundColor: '#198754', padding: '2px 5px', fontSize: 'smaller'}} onClick={close}>
                  X
                </Button>
                <MarkdownComponent filePath='/files/Reclaim-MLM-Terms-of-Engagement-Authority-Agreement.md' />  <button onClick={() => handleDownload('/files/Reclaim-MLM-Terms-of-Engagement-Authority-Agreement.pdf', 'My-Law-Matters-Terms.pdf')}>Download as PDF</button>
                </div>
              )}
            </Popup>
  }

  const privacyPolicy = () =>{
    return  <Popup trigger={<button className='sign-up-terms'>Privacy Policy</button>} modal>
              {close =>(
              <div style={{ height: '500px', overflowY: 'auto'}}>
                <Button label='X' style={{position: 'absolute', right:'0', top: '0', borderRadius: '0', backgroundColor: '#198754', padding: '2px 5px', fontSize: 'smaller'}} onClick={close}>
                  X
                </Button>
                  <MarkdownComponent filePath='/files/YCM-Privacy-Policy.md' />
                </div>
              )}
            </Popup>
  }

  const importantFacts = () =>{
    return  <Popup trigger={<button className='sign-up-terms'>Key Facts</button>} modal>
              {close =>(
              <div style={{ height: '500px', overflowY: 'auto'}}>
                <Button label='X' style={{position: 'absolute', right:'0', top: '0', borderRadius: '0', backgroundColor: '#198754', padding: '2px 5px', fontSize: 'smaller'}} onClick={close}>
                  X
                </Button>
                <MarkdownComponent filePath='/files/YCM-Car-Finance-Key-Facts.md' /> <button onClick={() => handleDownload('/files/YCM-Car-Finance-Key-Facts.pdf', 'Reclaim-Legal-Key-Facts.pdf')}>Download as PDF</button>
              </div>
              )}
            </Popup>
  }

  const termsText = () =>{
      return<> <p>If I have a potential claim, I consent for Your Claim Matters (YCM) to refer me to one of their law firm partners who will contact my lender(s) to verify my claim. YCM will also receive an electronic credit report from the credit information firm, Valid8 IP Ltd, and a soft footprint will be retained on my credit file which will not affect my credit rating. I have read and agree to the YCM and Partner Law Firm {termsAndConditions('Terms and Conditions')} and {privacyPolicy()}, and for my digital signature to be applied to the {termsAndConditions('Partner Law Firm Terms and Conditions and Letter of Authority')}. Please read the important {importantFacts()} document before submitting your claim. </p>
            {/*<p className='tips'>*Reclaim My PCP is a trading style of Reclaim Legal Ltd, with its trading office Hamilton House, Church Street, Altrincham, WA14 4DR. Reclaim Legal Limited is a limited company registered in England and Wales, company registration number 13438429), registered office Suite 103, 4 Montpelier Street, London, SW7 1EE, VAT number 348 571 864. Reclaim Legal is authorised and regulated by the Solicitors Regulation Authority, SRA no. 830575.</p>*/}
    </>}
  const creditCheckRequest = async() =>{
    isSubmitted(true)
    try {
      const response = await axios.post(CREDIT_CHECK(), getCreditCheck(compensate));
        const returnedData = response?.data?.message?.response?.data
        const previousAddresses = response?.data?.message?.response?.previous_addresses

       if(returnedData && previousAddresses){
        isSubmitted(false)
        let updatedClaims = [...returnedData]; // Copy the external claims data

        if (!compensate.creditCheckSuccess && compensate.registrationDetails) {
          const hasRegistrationDetailsObject = updatedClaims.some(
            claim => claim.reg && claim.reg !== ''
          );
    
          if (!hasRegistrationDetailsObject && compensate.registrationDetails.yearOfManufacture < 2021) {
            updatedClaims.push({
              lender_name: '',
              finance_agreement_number: '',
              date_finance_was_taken: '',
              account_type: '',
              make: compensate?.registrationDetails?.make,
              new_or_used: compensate.purchasedRegCar,
              vehicleRegistrationNumber: compensate.registrationDetails.registrationNumber,
              vehicleMakeYear: compensate.registrationDetails.yearOfManufacture.toString()
            });
          }
        }
        onNext({
          claims: updatedClaims,
          termsConditions,
          signature,
          previousAddress: [...(compensate.previousAddress || []), ...previousAddresses]
        },(formPages.CreditCheckSuccess))
       }else if(returnedData && !previousAddresses){
        isSubmitted(false)
        let updatedClaims = [...returnedData]; // Copy the external claims data

        if (!compensate.creditCheckSuccess && compensate.registrationDetails) {
          const hasRegistrationDetailsObject = updatedClaims.some(
            claim => claim.reg && claim.reg !== ''
          );
    
          if (!hasRegistrationDetailsObject && compensate?.registrationDetails?.yearOfManufacture < 2021) {
            updatedClaims.push({
              lender_name: '',
              finance_agreement_number: '',
              date_finance_was_taken: '',
              account_type: '',
              make: compensate?.registrationDetails?.make,
              new_or_used: compensate.purchasedRegCar,
              vehicleRegistrationNumber: compensate.registrationDetails.registrationNumber,
              vehicleMakeYear: compensate.registrationDetails.yearOfManufacture.toString()
            });
          }
        }
        onNext({
          claims: updatedClaims,
          termsConditions,
          signature,
        },(formPages.CreditCheckSuccess))
      }else{
        isSubmitted(false)
        onNext({
          claims: (() => {
            let updatedClaims = [...(compensate?.claims || [])];

            // Add object if credit check failed and registration details exist, but only if there's no object with a non-blank 'reg' key
            if (compensate.registrationDetails) {
              const hasRegistrationDetailsObject = updatedClaims.some(
                claim => claim.vehicleRegistrationNumber && claim.vehicleRegistrationNumber !== ''
              );

            // Add object if credit check failed and registration details exist
            if (!hasRegistrationDetailsObject && compensate?.registrationDetails?.yearOfManufacture < 2021) {
              updatedClaims.push({
                lender_name: '',
                finance_agreement_number: '',
                date_finance_was_taken: '',
                account_type: '',
                make: compensate?.registrationDetails?.make,
                new_or_used: compensate.purchasedRegCar,
                vehicleRegistrationNumber: compensate.registrationDetails.registrationNumber,
                vehicleMakeYear: compensate.registrationDetails.yearOfManufacture.toString()
              });
            }
          }                  
            return updatedClaims;
          })(),
          termsConditions,
          signature
        },( compensate?.registrationDetails?.yearOfManufacture < 2021 ? formPages.CreditCheckSuccess : formPages.RequestFinanceLender))
       }
    } catch (error) {
      console.error('Error fetching credit check:', error)
      isSubmitted(false)
      onNext({
        claims: (() => {
          let updatedClaims = [...(compensate?.claims || [])];

          // Add object if credit check failed and registration details exist, but only if there's no object with a non-blank 'reg' key
          if (compensate.registrationDetails) {
            const hasRegistrationDetailsObject = updatedClaims.some(
              claim => claim.vehicleRegistrationNumber && claim.vehicleRegistrationNumber !== ''
            );

          // Add object if credit check failed and registration details exist
          if (!hasRegistrationDetailsObject && compensate.registrationDetails.yearOfManufacture < 2021) {
            updatedClaims.push({
              lender_name: '',
              finance_agreement_number: '',
              date_finance_was_taken: '',
              account_type: '',
              make: compensate?.registrationDetails?.make,
              new_or_used: compensate.purchasedRegCar,
              vehicleRegistrationNumber: compensate.registrationDetails.registrationNumber,
              vehicleMakeYear: compensate.registrationDetails.yearOfManufacture.toString()
            });
          }
        }                  
          return updatedClaims;
        })(),
        termsConditions,
        signature,
      },(compensate.registrationDetails.yearOfManufacture < 2021 ? formPages.CreditCheckSuccess : formPages.RequestFinanceLender))
    }
  }

  return (<>
    <div className='d-flex justify-content-center align-items-center heading-icon fdc-form'>
      <img src='/icons/TyreMoney.png' alt='icon1' />
      <h2 className='title'>Please review and sign below for your FREE claim check.</h2>
    </div>
    <div className='fdc-form gif-input'>
      <div className='row'>
        <div className='col-md-12'>
          <div className='form-block'>
            <Label
              label={<>Please sign in the box below: <br /> <span style={{color: '#008f8f'}}>Replicate your handwritten signature, ensuring it's recognisable for your lender(s)</span></>}
            />
            <div className="signature" style={{ background: '#F2F2F2 0% 0% no-repeat padding-box', border: '1px solid #707070' }}>
                <SignatureCanvas
                  penColor='black'
                  canvasProps={{ height: 150, width: signaturePadWidth }}
                  onEnd={() => {
                    const strokeData = signatureRef.current.toData();

                    // Check if the canvas only has a dot (i.e., very small stroke data)
                    if (strokeData.length === 1 && strokeData[0].length === 1) {
                      setSignature('');
                      setsignError(true); // Set error if signature contains only a dot
                      console.log("Signature is only a dot");
                    }else{ 
                      setSignature(signatureRef.current._sigPad.toDataURL("image/png"))
                      setsignError(false)
                    }

                  }}
                  ref={signatureRef}

                />
            </div>
            <ButtonComponent
              label={<FontAwesomeIcon icon={["fa" , "rotate"]}/>}
              onClick={clearSig1}
              style={{textAlign: 'center', float: "right", minWidth: "0", marginTop: "-176px", zIndex: "1000", position: "relative"}}
            />
            {signatureOverlay && <ButtonComponent 
              className={'remove-animation'}
              label={<><FontAwesomeIcon icon={["fa" , "signature"]} size='4x' /> <br /> Click here and write your signature as it appears on your ID </>}
              onClick={() => setSignatureOverlay(false)}
              style={{textAlign: 'center', minWidth:'100%', height: '162px', marginTop: '-230px', zIndex: "2000", position: "relative", backgroundColor: '#008f8f', borderRadius: '0'}}              
            />}
            {signError?
                  <div>
                    <span className="text-danger">
                        <p>Please sign above</p>
                    </span>
                </div>
                :<></>
            }
            <div className="t-and-c-check">
              <ListRadioOptions
                  items={[{ text:"Click here to confirm your consent to the conditions below", value: true} ]}
                  onSelect={(value) => {
                    setTermsConditions(!termsConditions)
                    if(value === true){
                      setTermError(false)
                    }
                  }}
                  value={termsConditions}
                  className="ps-0 no-radius"
              />
              {termError ?
                <div>
                  <span className="text-danger">
                  <p>Please click the box above to confirm</p>
                  </span>
              </div>
              :<></>
              }
              {termsText()}
            </div>
          </div>
        </div>
        <div className='col-md-6 mx-auto text-center'>
          <FormActionButton
            onNext={() => {
              if(signature === '' || signature === undefined){ setsignError(true) }
              if(termsConditions !== true){ setTermError(true) }
              if(termsConditions === true && signature){
                creditCheckRequest()
              }
            }}
            onBack={() => {
              onBack(formPages.PreviousAddress)
            }}
            nextTitle='Submit'
          />
          <img src='/icons/No-Win-No-Fee.svg' className='icons my-2' alt='no win no fee icon'/>
          <br />
          <img className='light-bulb' src='/icons/Lightbulb-Tip.png' alt='light bulb'/><b className='tips'>Sit back, relax, and let us handle everything.  </b>
        </div>
      </div>
    </div>
  </>)
}
