import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentStep, updateFormData } from '../../store/actions/formActions'
import { CRM_SUBMIT_ENDPOINT, EMAIL_COMMS, formPages } from '../../constants';
import IntroCheckDetails from '../../components/form-pages/IntroCheckDetails';
import NewClaimantDetails from '../../components/form-pages/NewClaimantDetails';
import AddressDetails from '../../components/form-pages/AddressDetails';
import ContactDetails from '../../components/form-pages/ContactDetails';
import RegistrationDetails from '../../components/form-pages/RegistrationDetails'
import RegistrationResults from '../../components/form-pages/RegistrationResults';
import Signature from '../../components/form-pages/Signature';
import RequestFinanceLender from '../../components/form-pages/RequestFinanceLender';
import ConfirmLenders from '../../components/form-pages/ConfirmLenders';
import PreviousAddress from '../../components/form-pages/PreviousAddress';
import CreditCheckSuccess from '../../components/form-pages/CreditCheckSuccess';
import Summary from '../../components/form-pages/Summary';
import Success from '../../components/form-pages/Success';
import NoClaim from '../../components/form-pages/NoClaim';
import PageLayout from '../../layout/PageLayout';
import { faRotate, faShareNodes, faSignature, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import axios from 'axios';
import { commsBotData, getCaseInception } from './mapper';
import Error from '../../components/form-pages/Error';
import AddressLookup from '../../components/form-pages/AddressLookup';
import { v4 as uuidv4 } from 'uuid';
library.add(faRotate, faShareNodes, faSignature, faTrashCan)


export default function CarFinance() {

  const [isSubmitted, setIsSubmitted] = useState(false)
  const dispatch = useDispatch();
  
  const { currentStep, compensate } = useSelector(
    (state) => state.compensate
  );

  const onBack = (step) => {
    dispatch(setCurrentStep(step));
  };

  const onNext = (data, step) => {
    dispatch(setCurrentStep(step));
    dispatch(
      updateFormData({
        ...compensate,
        ...data
      })
    );
  };

  const onSubmit = async (data, step) => {
    setIsSubmitted(true);


    const uuid = uuidv4();

    const finalData = {
      ...compensate,
      ...data
    };

    dispatch(updateFormData(finalData));

    try {
      const response = await axios.post(CRM_SUBMIT_ENDPOINT(), getCaseInception(finalData));
      const emailComms = await axios.post(EMAIL_COMMS(), commsBotData(finalData, uuid))
      console.log(response);
      console.log(emailComms);
      dispatch(setCurrentStep(step));
    } catch (err) {
      console.error('Submission Error:', err);
      dispatch(
        updateFormData({
          ...compensate,
          error: err.response.data.message.detail
        })
      );
      dispatch(setCurrentStep(formPages.Error))
    } finally {
      setIsSubmitted(false);
    }
  };
  
 console.log('Current Step:', currentStep);
 // console.log('Compensate State:', compensate);

  const renderStep = () => {
      setTimeout(() => {
  
        window.scroll({ top: -1, left: 0, behavior: "smooth" });
    
    }, 10);   

    switch (currentStep) {
      case formPages.IntroCheckDetails:
        return <IntroCheckDetails compensate={compensate} onBack={onBack} onNext={onNext} isSubmitted={setIsSubmitted}/>
      case formPages.NewClaimantDetails:
        return <NewClaimantDetails compensate={compensate} onBack={onBack} onNext={onNext}/>
      case formPages.AddressDetails: 
        return <AddressDetails compensate={compensate} onBack={onBack} onNext={onNext}/>
      case formPages.AddressLookup:
        return <AddressLookup compensate={compensate} onBack={onBack} onNext={onNext}/> 
      case formPages.ContactDetails: 
        return  <ContactDetails compensate={compensate} onBack={onBack} onNext={onNext} isSubmitted={setIsSubmitted}/>
      case formPages.RegistrationDetails:
        return <RegistrationDetails compensate={compensate} onBack={onBack} onNext={onNext} isSubmitted={setIsSubmitted}/>
      case formPages.RegistrationResults:
        return <RegistrationResults compensate={compensate} onBack={onBack} onNext={onNext}/>
      case formPages.PreviousAddress:
        return <PreviousAddress compensate={compensate} onBack={onBack} onNext={onNext} />
      case formPages.Signature:
        return <Signature compensate={compensate} onBack={onBack} onNext={onNext} isSubmitted={setIsSubmitted}/>
      case formPages.RequestFinanceLender:
        return <RequestFinanceLender compensate={compensate} onBack={onBack} onNext={onNext}/>
      case formPages.ConfirmLenders:
        return <ConfirmLenders compensate={compensate} onBack={onBack} onNext={onNext}/>
      case formPages.CreditCheckSuccess:
        return <CreditCheckSuccess compensate={compensate} onBack={onBack} onNext={onNext}/>
      case formPages.Summary:
        return <Summary compensate={compensate} onBack={onBack} onNext={onNext} isSubmitted={setIsSubmitted} onSubmit={onSubmit}/>
      case formPages.Success:
        return <Success compensate={compensate} onBack={onBack} onNext={onNext}/>
      case formPages.NoClaim:
        return <NoClaim compensate={compensate} onBack={onBack} onNext={onNext}/>
      case formPages.Error: 
        return <Error compensate={compensate} />
      default:
        return <></>;
    }}

    const renderProgressBar = () =>{
      const formTitlesLength = (Object.keys(formPages).length - 2)
      return  <div className="stepper-loader align-items-center" style={{display: currentStep > formTitlesLength ? 'none' : 'flex'}}>
                <div style={{  width: currentStep < 2 ? '0%': currentStep === 13 ? '100%' : `${(100 / formTitlesLength) * (currentStep)}%`}}></div>
                <img style={{height: '60px', marginLeft: '-23px', marginRight: '-17px'}} src='/icons/Orange-car.svg' alt='orange car' />
              </div>
    }
  

      //track page by variable
  const keysArray = Object.keys(formPages)
  const currentPage = keysArray[currentStep];
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pageview',
      page_location: window.location.href,
      page_title: 'StartPage'
    });
  }, []); 

  useEffect(() => {
    const url = new URL(window.location.href);
    url.pathname = `/${currentPage}/`;
    window.history.pushState({}, '', url.toString());

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pageview',
      page_location: window.location.href,
      page_title: currentPage
    });
  },[currentPage])

  return (
    <PageLayout>
      <div className={currentPage}></div>
      <div className='container'>
        <div className='fdc-form-wrapp'>
          <div className="fdc-stepper-wrapp">
          {renderProgressBar()}
          </div>

          <div className={`fdc-shadow-block ${isSubmitted ? 'active-spinner' : ''}`}>
            <div className="loading-overlay"></div>
            <div className="loader"></div>
            {renderStep()} 
          </div>
        </div>
      </div>
    </PageLayout>
  )
}
