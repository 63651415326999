import React, { useState } from 'react'
import FormActionButton from '../shared-components/FormActionButton'
import ListRadioOptions from '../shared-components/ListRadioOptions'
import { claimValue, formPages } from '../../constants'
import Label from '../shared-components/Label'

export default function CreditCheckSuccess({compensate, onNext, onBack}) {

  const [hadOtherFinance, setHadOtherFinance] = useState(compensate.hadOtherFinance)
  const [hadOtherFinanceError, setHadOtherFinanceError] = useState(false)


  return (<>
    <div className='fdc-form'>
      <h2 className='title'>Congratulations! You have <span className='ycm-orange'>{compensate?.claims?.length}</span> potential {compensate?.claims?.length > 1 ? 'claims' : 'claim'} and
       you could be owed more than <span className='ycm-orange'>£{((compensate?.claims.length)  * claimValue).toLocaleString()}*</span></h2>
    </div>
    <div className='fdc-form gif-input'>
      <div className='row g-0'>
        <div className='col-md-12'>
          <div className='form-block'>
            {(!compensate.registrationDetails || (compensate.claims.length > 1 && compensate.registrationDetails)) && <Label label={<>We have checked your details and found the below {compensate?.claims?.length > 1 ? 'claims' : 'claim'}</>} />}
            {compensate?.claims?.map((claims, i) => (
              <div key={i} className='lender-block'>
                {claims?.lender_name}
                {claims?.date_finance_was_taken && <p className='mb-0'>Finance Start Date: {claims?.date_finance_was_taken }</p>}
                {claims?.make && <p className='mb-0'>Vehicle Make</p>}
                {claims?.make} {claims?.make && <span>-</span>} {claims?.new_or_used}  {claims?.vehicleRegistrationNumber 
                  && <>{<br />} Reg - {claims?.vehicleRegistrationNumber} {<br />} Year: {claims?.vehicleMakeYear}</>}
              </div>
            ))}
          </div>
        </div>
        <div className='col-md-12'>
          <div className='form-block'>
            <Label style={{color: '#008f8f'}} label={<>Add another car to claim for a further <span className='ycm-orange'>£{claimValue.toLocaleString()}*</span></>} />
            <ListRadioOptions
              label={<p>Have you taken out any additional car finance between April 2007 to January 2021?</p>}
              items={[{ text: "Yes", value: true }, { text: "No", value: false }]}
              onSelect={setHadOtherFinance}
              value={hadOtherFinance}
              className="d-inline-block"
              divStyle="full-width"
            />
            {hadOtherFinanceError ?
                      <div>
                        <span className="text-danger">
                            <p>Please select an option</p>
                        </span>
                    </div>
                    :<></>
                  }
          </div>
        </div>
        <div className='col-md-6 mx-auto text-center'>
          <FormActionButton
            onNext={() => {
              if(hadOtherFinance !== true && hadOtherFinance !== false){setHadOtherFinanceError(true)}
              if(hadOtherFinance === true || hadOtherFinance === false){
                  onNext({
                  hadOtherFinance,
                  creditCheckSuccess: true
                },(hadOtherFinance ? formPages.RequestFinanceLender : formPages.Summary))
              }
            }}
            />
            <br />
            <img className='light-bulb mb-2' src='/icons/Lightbulb-Tip.png' alt='light bulb'/><b className='tips'>Claim with confidence with Your Claim Matters</b>
            <br />
            <p className='terms'>*based on an average refund of hidden commissions of £1,100, plus 8% annual compensatory interest</p>
        </div>
      </div>
    </div>
  </>)
}
