// reducers/formReducer.js
import { formPages } from '../../constants';
import { UPDATE_FORM_DATA, SET_CURRENT_STEP } from '../actions/formActions';

const initialState = {
    compensate: {},
    currentStep: formPages.IntroCheckDetails, // Initial state should be set correctly
};

const formReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_FORM_DATA:
            return {
                ...state,
                compensate: { ...state.compensate, ...action.data },
            };
        case SET_CURRENT_STEP:
            return {
                ...state,
                currentStep: action.data,
            };
        default:
            return state;
    }
};

export default formReducer;
