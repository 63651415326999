import { useState } from 'react'
import Text from '../shared-components/Text'
import { ADDRESS_ENDPOINT, ADDRESS_RETRIEVE } from '../../constants'
import ButtonComponent from '../shared-components/Button'
import MuiSelect from '../shared-components/ReactSelect/ReactSelect'

export default function AddressDetails({compensate, onNext, onBack }) {

  const [addressData, setAddressData] = useState(compensate.currentAddressData ?? null)
  const [addressSelected, setAddressSelected] = useState(compensate.currentAddressSelected ?? '')
  const [address, setAddress] = useState(compensate.currentAddress ?? '')
  const [enterAddress1, setEnterAddress1] = useState(compensate.currentPostcode ?? '')

  const [enterAddress1Error, setEnterAddress1Error] = useState(false)
  const [addressSelectedError, setAddressSelectedError] = useState(false)


  const addressSearch = async (addressValue) => {
    const data = await ADDRESS_ENDPOINT(addressValue) 
    setAddressData(data)
    setAddressSelected('')
    setAddress('')
  }

  const selectOptions = addressData?.map((item) => ({
    value: item.Id,
    text: item.Text,
    description: item.Description
  }));

  const handleAddressChange = async(e) =>{
    const returnedContainerId = e.value
    const descriptionExists = addressData?.find(item => item.Description)
    if(descriptionExists){
      const data = await ADDRESS_ENDPOINT(enterAddress1, returnedContainerId) 
      setAddressData(data)
    } else {
      const data = await ADDRESS_RETRIEVE(returnedContainerId)
      setAddress(data)
      setAddressSelected(e)
    }
  }

  return (<>
    <div className="d-flex justify-content-center align-items-center heading-icon fdc-form">
        <img src='/icons/TyreHouse.png' alt='icon2'/>
        <h2 className="title">Please provide your address details</h2>
    </div>
    <div className='fdc-form gif-input'>   
      <div className="form-block row d-flex align-items-end">
        <div className="col-9 pe-0">
            <Text 
                label={"Address Search"}
                htmlFor="UK Address Search"
                onChange={(e) => {
                  setEnterAddress1(e.target.value);
                  setEnterAddress1Error(false)
                }}
                id="UK Address Search"
                value={enterAddress1}
                placeholder={'Enter your postcode here'}
            />
        </div>
        <div className="col-3 px-0">
            <ButtonComponent
                label={`Search`}
                onClick={() => addressSearch(enterAddress1)}
                style={{padding: '11px 15px', marginTop: '8px', minWidth:'0px', backgroundColor: '#008F8F', width: '100%'}}
            />
        </div>
        {enterAddress1Error ?
          <div>
            <span className="text-danger">
                <p>Please enter your postcode</p>
            </span>
          </div>
          :<></>
        }
      </div>
      <div className='row'>
        {addressData?.length && <>
          <div className='col-md-12'>  
            <div className='form-block'>
              <MuiSelect
                label={'Address Select'}
                options={selectOptions}
                placeholder={'Click here to select your address'}
                onChange={e => {
                  handleAddressChange(e);
                  setAddressSelectedError(false)
                }}
                value={addressSelected}
              />
              {addressSelectedError ?
                <div>
                  <span className="text-danger">
                      <p>Please select an address</p>
                  </span>
                </div>
                :<></>
              }
            </div>
          </div>
          <div className='col-md-12'>
            <div className='box-message fw-semibold theme-color-1 text-center'>
              {address?.Company && <p className='mb-0'>{address?.Company}</p>}
              {address?.Line1 && <p className='mb-0'>{address?.Line1}</p>}
              {address?.Line2 && <p className='mb-0'>{address?.Line2}</p>}
              {address?.City && <p className='mb-0'>{address?.City}</p>}
              {address?.CountryName && <p className='mb-0'>{address?.CountryName}</p>}
              {address?.PostalCode && <p className='mb-0'>{address?.PostalCode}</p>}
            </div>
          </div>
        </>}
      </div>  
    </div>  </>)
}
