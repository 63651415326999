import React, { useEffect, useState } from 'react'
import FormActionButton from '../shared-components/FormActionButton'
import { ADDRESS_ENDPOINT, ADDRESS_RETRIEVE, claimValue, CLIENT_DETAILS, formPages, VALIDATE_EMAIL } from '../../constants'
import ListRadioOptions from '../shared-components/ListRadioOptions'
import axios from 'axios'


export default function IntroCheckDetails({compensate, onNext, onBack,  isSubmitted}) {
  const [correctDetails, setCorrectDetails] = useState(compensate.correctDetails)
  const [correctDetailsError, setCorrectDetailsError] = useState(false)
  const [clientDetails, setClientDetails] = useState([])
  const marketingChannel = new URLSearchParams(window.location.search).get('utm_source')
  const marketingCampaignName = new URLSearchParams(window.location.search).get('utm_campaign')
  const marketingAudience = new URLSearchParams(window.location.search).get('utm_term')
  const marketingCreative = new URLSearchParams(window.location.search).get('utm_content')

  const averageClaimValue = claimValue *2
  useEffect(() => {
    const fetchClientDetails = async() => {
      isSubmitted(true)
      const uid = new URLSearchParams(window.location.search).get('id')
      if(uid === "address"){
        onNext({},(formPages.AddressLookup))
        isSubmitted(false)
      } else if(uid){
          try {
          const response = await CLIENT_DETAILS(uid)
          setClientDetails(response)
          const validEmail = !response.email ? false : await VALIDATE_EMAIL(response?.email)
          if((!response.email && !response.telephone) || !validEmail){
            onNext({
              title: response?.title,
              firstName: response?.first_name,
              surname: response?.last_name,
              dob: response?.date_of_birth,
              mobile: response?.telephone,
              email: response?.email,
              uid: uid,
              claims: [],
              marketingChannel,
              marketingCampaignName,
              marketingAudience,
              marketingCreative
            },(formPages.NewClaimantDetails))
          }
          isSubmitted(false)
        } catch (error) {
          console.error('Error fetching client details:', error)
          isSubmitted(false)
          onNext({},(formPages.Error))
        }
      }else{
        isSubmitted(false)
        onNext({
          claims: [],
          marketingChannel,
          marketingCampaignName,
          marketingAudience,
          marketingCreative
        },(formPages.NewClaimantDetails))
      }
    }
    fetchClientDetails()
  },[])

  const directAddressSearch = async() => {
   if(clientDetails.address){
    const fullAddress = clientDetails.address.address_line_1 +' '+ clientDetails.address?.address_line_2 +' '+ clientDetails.address?.address_city +' '+ clientDetails.address.address_postcode
    try {
      const addressid = await ADDRESS_ENDPOINT(fullAddress.trim())
      const loqateAddress = await ADDRESS_RETRIEVE(addressid[0].Id)
      console.log(loqateAddress);
      if(!loqateAddress?.Error && correctDetails === true){
        onNext({
          correctDetails,
          title: clientDetails?.title,
          firstName: clientDetails?.first_name,
          surname: clientDetails?.last_name,
          dob: clientDetails?.date_of_birth,
          mobile: clientDetails?.telephone,
          email: clientDetails?.email,
          uid: clientDetails?.uid,
          currentAddress: loqateAddress,
          claims: [],
          marketingChannel,
          marketingCampaignName,
          marketingAudience,
          marketingCreative
        },(formPages.RegistrationDetails));
      }else{
        onNext({
          correctDetails,
          title: clientDetails?.title,
          firstName: clientDetails?.first_name,
          surname: clientDetails?.last_name,
          dob: clientDetails?.date_of_birth,
          mobile: clientDetails?.telephone,
          email: clientDetails?.email,
          uid: clientDetails?.uid,
          marketingChannel,
          marketingCampaignName,
          marketingAudience,
          marketingCreative
        },(formPages.NewClaimantDetails));
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }}
return (<>
    <div className="d-flex justify-content-center align-items-center heading-icon fdc-form">
      <img src='/icons/TyreMoney.png' alt='icon1'/>
      <h2 className="title">You could be owed an average of <span className='ycm-orange'>£{averageClaimValue.toLocaleString()}*</span> based
      on 2 cars per household for mis-sold car finance. Check for FREE in 60 seconds.
</h2>
    </div>
    <div className='fdc-form gif-input'>
      <div className='row'>
        <div className='col-md-12'>
          <div className='fw-semibold theme-color-1'>
            As a valued customer, we want to make sure your information we hold is correct. Please take a moment to review the details below:
          </div>
        </div>
        <div className="col-md-12">
            <div className="box-message fw-semibold theme-color-1">
              <p>{clientDetails?.title} {clientDetails?.first_name} {clientDetails?.last_name} </p>
              {clientDetails?.address?.address_line_1 && <p className='mb-0'>{clientDetails?.address?.address_line_1}</p>}
              {clientDetails?.address?.address_line_2 && <p className='mb-0'>{clientDetails?.address?.address_line_2}</p>}
              {clientDetails?.address?.address_line_3 && <p className='mb-0'>{clientDetails?.address?.address_line_3}</p>}
              {clientDetails?.address?.address_city && <p className='mb-0'>{clientDetails?.address?.address_city}</p>}
              {clientDetails?.address?.address_postcode && <p className='mb-0'>{clientDetails?.address?.address_postcode}</p>}
              <p className='mt-3'>{clientDetails?.date_of_birth && 'Date of Birth:'} {clientDetails?.date_of_birth}</p>
              <p>{clientDetails?.telephone && 'Telephone:'} {clientDetails?.telephone}</p>
              <p>{clientDetails?.email && 'Email:'} {clientDetails?.email}</p>
            </div>
        </div>
        <div className="col-md-12">
          <div className="form-block mb-0">
            <ListRadioOptions
                label={'Are these details correct?'}
                items={[{ text: "Yes", value: true }, { text: "No", value: false }]}
                onSelect={(val) => {
                    setCorrectDetails(val)
                    setCorrectDetailsError(false)
                }}
                value={correctDetails}
                className="d-inline-block"
                divStyle="full-width"
            />
            {correctDetailsError?
                  <div>
                    <span className="text-danger">
                        <p>Please select an option</p>
                    </span>
                </div>
                :<></>
              }
          </div>
        </div>
        <div className='col-md-12'>
          <div className='fw-semibold text-center theme-color-1'>
            We'll check your claim for free
          </div>
        </div>
        <div className="col-md-6 text-center mx-auto">
          <FormActionButton
            onNext={() =>{
              if(correctDetails !== true && correctDetails !== false){setCorrectDetailsError(true)}
              if(correctDetails === true || correctDetails === false){
                directAddressSearch()
              }
            }}
          />
          <br />
          <img className='light-bulb mb-2' src='/icons/Lightbulb-Tip.png' alt='light bulb'/><b className='tips'>Claim with confidence with Your Claim Matters</b>
          <br />
          <p className='terms'>*based on an average refund of hidden commissions of £1,100, plus 8% annual compensatory interest, and an average of two cars per household.</p>
        </div>
      </div>
    </div>
  </>)
}
