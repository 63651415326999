import React from 'react'
import './Footer.css'

export default function Footer() {
  return (
    <footer>
      <div className='container'>
        <div className="row g-0">
          <div className="py-3">
          </div>
        </div>
      </div>
    </footer>
  )
}
