import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import './MarkdownStyles.css'


const MarkdownRenderer = ({ filePath }) => {
  const [content, setContent] = useState('');

  useEffect(() => {
    fetch(filePath)
      .then((response) => response.text())
      .then((text) => setContent(text));
  }, [filePath]);

  // Override the rendering of <a> tag (links)
  const components = {
    a: ({ children }) => <span>{children}</span>, // Render link text without making it clickable
  };

  return <ReactMarkdown
            remarkPlugins={remarkGfm} components={components}>
              {content}
        </ReactMarkdown>
};

export default MarkdownRenderer;