export const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA';
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';

export const updateFormData = (data) => ({
    type: UPDATE_FORM_DATA,
    data,
});

export const setCurrentStep = (step) => ({
    type: SET_CURRENT_STEP,
    data: step,
});