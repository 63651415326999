import { Form } from 'react-bootstrap'
import './ListRadioOptions.css'

export default function ListRadioOptions({ label, items, value, onSelect, className, divStyle, id }) {
    const Item = ({ item }) => {

        return (
            <>
                <Form.Check
                    className={className}
                    label={item.text}
                    type={'checkbox'}
                    value={item.value}
                    onChange={() => {
                        onSelect(item.value)
                    }}
                    checked={item.value === value}
                    id={`checkbox-${item.value}-${id}`}
                    htmlFor={`checkbox-${item.value}`}
                />
            </>
        )
    }

    return (
        <div className={`list-radio-options ${divStyle}`} >
            {label && <Form.Label>{label}</Form.Label>}
            {items.map((item) => <Item key={item.value} item={item} />)}
        </div>
    )
}