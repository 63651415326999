import React, { useState } from 'react';
import Select from 'react-select';

export default function ReactSelect({ label, placeholder, options = [], value, onChange }) {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  // Handle when the dropdown should open
  const handleMenuOpen = () => {
    setMenuIsOpen(true);
  };

  // Handle when the dropdown should close
  const handleMenuClose = () => {
    setMenuIsOpen(false);
  };

  // Handle when a selection is made
  const handleChange = (selectedOption) => {
    // Find the selected item from the options array
    const selectedItem = options.find((item) => item.value === selectedOption.value);
    console.log(selectedItem && selectedItem.description && selectedItem.description.trim() !== '')
    // Update the selected value in the parent component first
    onChange(selectedOption);

    // Then, based on the description, control whether to keep the menu open or close it
    if (selectedItem.description) {
      // Keep the menu open if a valid description exists
      setMenuIsOpen(true);
    } else {
      // Close the menu if no description exists
      setMenuIsOpen(false);
    }
  };

  return (
    <div>
      <label>{label}</label>
      <Select
        value={value} // Make sure the value prop is an object { value, text }
        onChange={handleChange} // Handle the selection change
        options={options} // Ensure the options prop has { value, text, description }
        placeholder={placeholder}
        menuIsOpen={menuIsOpen} // Control the menu's open state manually
        onMenuOpen={handleMenuOpen} // Handle opening the menu
        onMenuClose={handleMenuClose} 
        // Handle closing the menu
        getOptionLabel={(option) =>
            option.description ? `${option.text} ${option.description}` : option.text
          }  // Use the 'text' field as the label
        getOptionValue={(option) => option.value} 
        closeMenuOnSelect={false}
        blurInputOnSelect={false}// Use the 'value' field as the value
      />
    </div>
  );
}
