import React, { useRef, useState, useEffect } from 'react'
import FormActionButton from '../shared-components/FormActionButton'
import { formPages, HTML_TO_PDF } from '../../constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isValidMobile } from '../../utils/utils'
import Text from '../shared-components/Text'
import Label from '../shared-components/Label'
import { useDispatch } from 'react-redux';
import { updateFormData } from '../../store/actions/formActions';

export default function Summary({compensate, onBack,  onSubmit, isSubmitted}) {

  const [summaryContent, setSummaryContent] = useState('')
  const [mobile, setMobile] = useState(compensate.mobile ?? '')
  const [mobilePrompt, setMobilePrompt] = useState(true)
  const [mobileNumberError, setMobileNumberError] = useState(false)
  const [border, setBorder] = useState(true)
  const [mobileField, setMobileField] = useState(true)
  const divRef = useRef(null)

  const dispatch = useDispatch();

  useEffect(() => {
    if (divRef.current) {
      setSummaryContent(divRef.current.innerHTML);  // Capture and set HTML content
    }
  }, [mobile, border, mobileField, compensate.claims]);

  const countDuplicates = (array) => {
    const countMap = {};
  
    array.forEach(({ make, lender_name, new_or_used, vehicleRegistrationNumber, vehicleMakeYear}) => {
      const key = `${make}-${lender_name}`;
      
      if (!countMap[key]) {
        countMap[key] = { 
          make, 
          lender_name, 
          reg: vehicleRegistrationNumber || null, // Store reg directly if it exists, otherwise set to null
          year: vehicleMakeYear || null,
          count: 0, 
          new_count: 0, 
          used_count: 0 
        };
      }
    
      // Increment the count
      countMap[key].count += 1;
    
      // Increment new or used counts
      if (new_or_used === 'New') {
        countMap[key].new_count += 1;
      } else if (new_or_used === 'Used') {
        countMap[key].used_count += 1;
      }
    });
    
    
  
    return Object.values(countMap);
  };

  const mobileSummary = () => {
    if(!compensate.mobile || compensate.mobile === ''){
      return <> 
        {mobilePrompt === true ?<Label 
          label={<><span style={{color: '#008f8f'}}>Please provide your mobile number for updates on your claim.</span></>}
        /> : <></>}
        <Text
        onChange={(e) => {
          setMobile(e.target.value)
          if(isValidMobile(e.target.value)){
            setMobileNumberError(false)
            setMobilePrompt(false)
          }
        }}
        style={{border: !border && 'unset' }}
        placeholder={'Please enter your Mobile Number'}
        value={mobile}
        id={'Mobile Number'}
      />
        {mobileNumberError?
              <div>
                <span className="text-danger">
                    <p>Please enter a valid mobile number</p>
                </span>
            </div>
            :<></>
          }</>
    } else{
      return compensate?.mobile
    }
  }

  const summaryClaimsArray = countDuplicates(compensate?.claims);
  console.log(summaryClaimsArray)
  console.log(summaryContent)

  const submitContent = async () => {
    isSubmitted(true)
    try {
      const base64Content = await HTML_TO_PDF(summaryContent)
      if(base64Content){
        onSubmit({
          summaryContent,
          base64Content,
          mobile
        },(formPages.Success))
      }else{
        onSubmit({
          summaryContent,
          mobile
        },(formPages.Success))
      }
    } catch (error) {
      console.error('Error submitting content', error)
      throw error
    } finally{
      isSubmitted(false)
    }
  }

  const deleteClaim = (indexToDelete) => {
    const updatedClaims = compensate.claims.filter((_, index) => index !== indexToDelete);

    // Dispatch the updated claims array to Redux
    dispatch(updateFormData({
      ...compensate,
      claims: updatedClaims
    }));
  }
  const returnedText = (claims) => {
    if(claims?.lender_name && claims?.date_finance_was_taken !== ''){
      return 'The claim YCM have found'
    }else if(claims?.lender_name && claims?.date_finance_was_taken === ''){
      return 'The lender you have selected'
    }else if(claims?.vehicleRegistrationNumber){
      return 'The registration number provided'
    } else if(claims?.make && !claims?.vehicleRegistrationNumber){
      return 'The make you have selected'
    }
  }

  return (<>
    <div className='d-flex justify-content-center align-items-center heading-icon fdc-form'>
      <img src='/icons/TyreMoney.png' alt='icon1' />
      <h2 className='title'>Please review your claim summary</h2>
    </div>
    <div  className='fdc-form gif-input'>
      <div className='row'>
        <div ref={divRef} className='col-md-12'>
          <div className='form-block'>
            {compensate.claims?.map((claims, i) => (
              <div key={i} className='row'>
                <Label label={`Your vehicle ${i+1} = ${returnedText(claims)} `} />
                <div className='lender-block d-flex w-100 justify-content-between' style={{backgroundColor: '#cbf2f9'}}>
                  <div>
                    {claims?.lender_name}
                    {claims?.date_finance_was_taken && <> <br /> Finance Start Date: {claims?.date_finance_was_taken }</>}
                    {claims?.make && <>Vehicle Make <br /></>}
                    {claims?.make} {claims?.make && <span>-</span>} {claims?.new_or_used}  {claims?.vehicleRegistrationNumber 
                      && <>{<br />} Reg - {claims?.vehicleRegistrationNumber} {<br />} Year: {claims?.vehicleMakeYear}</>}
                    </div>
                   {claims?.date_finance_was_taken === '' && !claims?.vehicleRegistrationNumber && <div><button onClick={() => deleteClaim(i)}><FontAwesomeIcon icon={["fa", "trash-can"]} /></button></div>}
                   
               </div><br />
              </div>
            ))}
            <hr />
            <p><span className='fw-bold'>Name:</span> {compensate.title} {compensate?.firstName} {compensate?.surname}</p>
            {compensate?.previousName && <> <span className='fw-bold'>Previous Name:</span> {compensate?.previousName} </>}
            <hr />
            <p className='mb-0 fw-bold'>Address: </p>
              {compensate.currentAddress?.Company && <p style={{textIndent: '20px', marginBottom: '0px'}}>{compensate.currentAddress?.Company}</p>}
              {compensate.currentAddress?.Line1 && <p style={{textIndent: '20px', marginBottom: '0px'}}>{compensate.currentAddress?.Line1}</p>}
              {compensate.currentAddress?.Line2 && <p style={{textIndent: '20px', marginBottom: '0px'}}>{compensate.currentAddress?.Line2}</p>}
              {compensate.currentAddress?.City && <p style={{textIndent: '20px', marginBottom: '0px'}}>{compensate.currentAddress?.City}</p>}
              {compensate.currentAddress?.CountryName && <p style={{textIndent: '20px', marginBottom: '0px'}}>{compensate.currentAddress?.CountryName}</p>}
              {compensate.currentAddress?.PostalCode && <p style={{textIndent: '20px'}}>{compensate.currentAddress?.PostalCode}</p>}
              <hr />
            {compensate?.previousAddress?.length ? <p className='mb-0 fw-bold'>Previous Addresses:</p> : <div></div>}
              {compensate?.previousAddress[0]?.Company && <p style={{textIndent: '20px', marginBottom: '0px'}}>{compensate?.previousAddress[0]?.Company}</p>}
              {compensate?.previousAddress[0]?.Line1 && <p style={{textIndent: '20px', marginBottom: '0px'}}>{compensate?.previousAddress[0]?.Line1}</p>}
              {compensate?.previousAddress[0]?.Line2 && <p style={{textIndent: '20px', marginBottom: '0px'}}>{compensate?.previousAddress[0]?.Line2}</p>}
              {compensate?.previousAddress[0]?.City && <p style={{textIndent: '20px', marginBottom: '0px'}}>{compensate?.previousAddress[0]?.City}</p>}
              {compensate?.previousAddress[0]?.CountryName && <p style={{textIndent: '20px', marginBottom: '0px'}}>{compensate?.previousAddress[0]?.CountryName}</p>}
              {compensate?.previousAddress[0]?.PostalCode && <p style={{textIndent: '20px', marginBottom: compensate?.previousAddress[1]?.PostalCode && '0px'}}>{compensate?.previousAddress[0]?.PostalCode}</p>}
              {compensate?.previousAddress[0]?.PostalCode && <><br/></>}
              {compensate?.previousAddress[1]?.Company && <p style={{textIndent: '20px', marginBottom: '0px'}}>{compensate?.previousAddress[1]?.Company}</p>}
              {compensate?.previousAddress[1]?.Line1 && <p style={{textIndent: '20px', marginBottom: '0px'}}>{compensate?.previousAddress[1]?.Line1}</p>}
              {compensate?.previousAddress[1]?.Line2 && <p style={{textIndent: '20px', marginBottom: '0px'}}>{compensate?.previousAddress[1]?.Line2}</p>}
              {compensate?.previousAddress[1]?.City && <p style={{textIndent: '20px', marginBottom: '0px'}}>{compensate?.previousAddress[1]?.City}</p>}
              {compensate?.previousAddress[1]?.CountryName && <p style={{textIndent: '20px', marginBottom: '0px'}}>{compensate?.previousAddress[1]?.CountryName}</p>}
              {compensate?.previousAddress[1]?.PostalCode && <p style={{textIndent: '20px'}}>{compensate?.previousAddress[1]?.PostalCode}</p>}
              {compensate?.previousAddress[1]?.PostalCode && <><hr /></>}
            <p><span className='fw-bold'>Date of Birth:</span> {compensate?.dob}</p>
            {mobileField ? <p><span className='fw-bold'>Mobile:</span> {mobileSummary()} </p>: <></>} 
            <p><span className='fw-bold'>Email:</span> {compensate?.email}</p>
          </div>
        </div>
        <div className='col-md-6 mx-auto text-center'>
          <FormActionButton 
            onSubmit={() => {
              if(!isValidMobile(mobile) && mobile?.length > 0){ setMobileNumberError(true) }
              if(isValidMobile(mobile) && mobile?.length > 0){setBorder(false); submitContent();}
              if(!isValidMobile(mobile) && mobile?.length === 0){setMobileField(false); submitContent();}
            }}
            submitTitle='Submit'
            onBack={() => {
              onBack(formPages.ConfirmLenders)
            }}
          />
        </div>
      </div>
    </div>
  </>)
}
