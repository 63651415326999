// store.js
import { createStore, combineReducers } from 'redux';
import formReducer from './reducers/formReducer';

const rootReducer = combineReducers({
    compensate: formReducer, // Ensure this matches your component's selector
});

const store = createStore(rootReducer);

export default store;
