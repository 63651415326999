import React, { useState } from 'react'
import MyQRCodeComponent from '../shared-components/QrCode/MyQRCodeComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareNodes } from '@fortawesome/free-solid-svg-icons';
import Popup from 'reactjs-popup';
import { CUSTOMER_REVIEW } from '../../constants';

export default function Success({compensate}) {

  const [openModal, setOpenModal] = useState(false)
  const [openReview, setOpenReview] = useState(true)
  const [reviewRating, setReviewRating] = useState('')

  const showVoucherSection = false;
  console.log(reviewRating)
  console.log(openModal)

  const handleShare = async () => {
    const currentUrl = window.location.href;
    const data = `${currentUrl}?r=${compensate.uid}`;
    if(navigator.share){
      try {
        await navigator.share({
          title: 'Car Finance Claims',
          text: 'Here is the site I used to claim for my flight!',
          url: data,
        });
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else{
      navigator.clipboard.writeText(data)
      .then(() => {
        setOpenModal(true)
      })
      .catch((err) => {
        console.error('Error copying to clipboard:', err);
      });
    }
  };
  
  const submitReview = (reviewRating) =>{
    setReviewRating(reviewRating);
    setOpenReview(false);
    CUSTOMER_REVIEW(compensate, reviewRating)
  }

  const reviewPopup = () => {
    return <Popup open={openReview} modal>
              {close => (
                  <div className="row">
                    <div className="col-md-12">
                        <div className='form-block text-center'>
                        <p style={{fontWeight: 'bolder'}}>Thank you for submitting your claim. We'd love to know how you would rate your experience with us by selecting one of the below</p>
                        </div>
                    </div>
                    <div className='col-md-12'>
                      <div className="form-block review-popup d-flex justify-content-center">
                        <div className='col-xl-6 d-flex justify-content-between align-items-baseline'>
                          <button onClick={() => submitReview('Fairly Good')}><img src='/icons/Fairly-Good.jpg' alt='Fairly Good'/> <span style={{fontWeight: 'bolder'}}>Fairly Good</span></button>
                          <button onClick={() => submitReview('Good')}><img src='/icons/Good.jpg' alt='Good' /> <span style={{fontWeight: 'bolder'}}>Good</span></button>
                          <button onClick={() => submitReview('Excellent')}><img src='/icons/Excellent.jpg' alt='Excellent' /> <span style={{fontWeight: 'bolder'}}>Excellent</span></button>
                        </div> 
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className='form-block text-center'>
                        <p style={{fontWeight: 'bolder'}}>Thank you, and your feedback is greatly appreciated.</p>
                      </div>
                    </div>
                </div>
              )}
            </Popup>
  }

  return (
    <>
      <div className='fdc-form'>
        <h2 className='title text-center'>Thank you for submitting your claim!</h2>
      </div>
      <div className='fdc-form gif-input'>
        <div className='row'>
          <div className='col-md-12'>
            {reviewPopup()}
            <div className='box-message pb-0 fw-semibold theme-color-1 text-center'>
              <p>Claim Submission Reference Number: {compensate?.uid}</p>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='box-message fw-semibold theme-color-1'>
              <img src='/icons/Orange-car.svg' alt='orange car' className='d-block mx-auto' style={{width: '100px'}}/>
              <p><span style={{color: '#008F8F'}}>Hi {compensate.firstName},</span></p>
              <p><span style={{color: '#008F8F'}}>Exciting News!</span> Your claim has been successfully submitted.</p>
              <p>We've just sent you a confirmation email. If it doesn't appear in your inbox, please check your 'Junk' or 'Spam' folders.</p>
              <p>Now, you can sit back and let us handle everything. We'll keep you updated at every stage, so you're always informed about your claim's progress.</p>
              <p>If you have any questions, don't hesitate to email us at <a href='mailto:info@ycmcarfinanceclaims.co.uk'>info@ycmcarfinanceclaims.co.uk</a>.</p>
              <p>Thanks for trusting us with your claim!</p>
              <p>Kind regards,<br />Your Senior Claim Advisor</p>
            </div>
          </div>
          {showVoucherSection && (<>
            <div className='col-md-12'>
              <div className='box-message fw-semibold theme-color-1 text-center'>
                <img src='/icons/Amazon-£50-Voucher.png' alt='voucher' style={{ maxWidth: '100%' }} />
                <p className='ycm-orange'>Refer your friends and family and earn £50* in Amazon vouchers for every new successful referral we receive.</p>
                <p>Share the QR code with your friends and family via WhatsApp or message:</p>
                <MyQRCodeComponent compensate={compensate} />
              </div>
            </div>
          
          <div className='col-md-12'>
            <div className="py-2">
              <button
                onClick={handleShare}
                style={{ padding: '15px', backgroundColor: '#001A42', color: '#fff', borderRadius: '5px' }}
                className='d-block mx-auto'>
                <div className="d-flex align-items-center">
                  <b className="pe-2">SHARE </b>
                  <FontAwesomeIcon icon={faShareNodes} size="2x" />
                </div>
              </button>
            </div>
          </div></>)}
        </div>
      </div>
    </>
  );
}