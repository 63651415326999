import React, { useState } from 'react'
import ListRadioOptions from '../shared-components/ListRadioOptions'
import FormActionButton from '../shared-components/FormActionButton'
import { formPages, REG_CHECK } from '../../constants'
import Text from '../shared-components/Text'
import axios from 'axios'

export default function RegistrationDetails({compensate, onNext, onBack, isSubmitted}) {

  const [hasRegistration, setHasRegistration] = useState(compensate.hasRegistration)
  const [registrationNumber, setRegistrationNumber] = useState(compensate.registrationNumber ?? '')
  const [registrationDetails, setRegistrationDetails] = useState(compensate.registrationDetails)

  const [hasRegistrationError, setHasRegistrationError] = useState(false)
  const [registrationNumberError, setRegistrationNumberError] = useState(false)
  const [dvlaError, setDvlaError] = useState(false)

  const regCheck = async() => {
    isSubmitted(true)
    try {
      const response = await axios.post(REG_CHECK(),{
        registrationNumber: registrationNumber
      }) 
      setRegistrationDetails(response.data.message)
      console.log(registrationDetails)
      if(!response.data.message.error){
        onNext({
          hasRegistration,
          registrationNumber,
          registrationDetails: response.data.message
        },(formPages.RegistrationResults))
      }
    } catch (error) {
      console.error("error fetching registration details", error)
      setDvlaError(true)
      setRegistrationDetails(null)
    } finally{
      isSubmitted(false)
    }
  }

return (<>
    <div className="d-flex justify-content-center align-items-center heading-icon fdc-form pb-0">
      <img src='/icons/TyreQuestion.png' alt='icon4' />
      <h2 className="title">Do you have the registration number for the vehicle you purchased on finance before 2021?</h2>
    </div>
    <div className='fdc-form gif-input pt-0'>
      <div className='row'>
        <div className="col-md-12">
          <div className="form-block">
            <ListRadioOptions
                items={[{ text: "Yes", value: true }, { text: "No", value: false }]}
                onSelect={(val) => {
                    setHasRegistration(val)
                    setHasRegistrationError(false)
                }}
                value={hasRegistration}
                className="d-inline-block"
                divStyle="full-width"
            />
            {hasRegistrationError?
                  <div>
                    <span className="text-danger">
                        <p>Please select an option</p>
                    </span>
                </div>
                :<></>
              }
          </div>
        </div>
       { hasRegistration === true && <>
          <div className='col-md-12'>
            <div className='form-block'>
              <Text
                label={'Please provide your registration Number:'}
                value={registrationNumber}
                onChange={(e) => {
                  const upperCaseValue = e.target.value.toUpperCase()
                  setRegistrationNumber(upperCaseValue)
                  if(registrationNumber.length > 0){
                    setRegistrationNumberError(false)
                  }
                }}
                id={'Registration Number'}
                placeholder={'Type registration number here'}
              />
              {registrationNumberError?
                  <div>
                    <span className="text-danger">
                        <p>Please enter a valid registration number</p>
                    </span>
                </div>
                :<></>
              }
              {dvlaError ? 
                <div>
                  <span className='text-danger'>
                    <p>
                      Oops! We couldn't find the registration number you entered. Don't worry,
                      you'll have another chance to add your vehicle later. For now, just select
                      'No' and click 'Next' to keep going.
                    </p>
                  </span>
                </div> : <></>}
            </div>
          </div>
          <div className='col-md-12'>
            <div className='box-message fw-semibold theme-color-1'>
            <img className='light-bulb' src='/icons/Lightbulb-Tip.png' alt='light bulb'/>
              <span className='ycm-orange '>You could be owed £'000s</span><br />
              75% of car finance agreements were mis-sold, with some lenders allowing brokers and dealers to increase interest rates for higher commissions, meaning customers unknowingly overpaid!
            </div>
          </div>
        </>}
        <div className='col-md-6 mx-auto text-center'>
          <FormActionButton
            onNext={() => {
              if(hasRegistration !== true && hasRegistration !== false){setHasRegistrationError(true)}
              if(hasRegistration === true && registrationNumber.length === 0 ){setRegistrationNumberError(true)}
              if((hasRegistration === true && registrationNumber.length > 0)){
                regCheck()
              }else if(hasRegistration === false){
                onNext({
                  hasRegistration
                },(formPages.PreviousAddress))
              }
            }}
            onBack={() => {
              onBack(formPages.ContactDetails)
            }}
          />
        </div>
      </div>
    </div>

  </>)
}
