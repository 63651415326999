import './App.css';
import store from './store';
import CarFinance from './containers/CarFinance';
import { Provider } from 'react-redux';
import { useEffect } from 'react';


function App() {

    // lock site for current customers only
    const uid = new URLSearchParams(window.location.search).get('id')
    useEffect(() => {
      if(!uid){
        window.location.replace("https://google.com")
      }
    },[uid])
  
  return (
    <Provider store={store}>
      <CarFinance />
    </Provider>
  );
}

export default App;
