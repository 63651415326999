import React, { useState } from 'react'
import FormActionButton from '../shared-components/FormActionButton'
import { claimValue, formPages } from '../../constants'
import ListRadioOptions from '../shared-components/ListRadioOptions'
import Label from '../shared-components/Label'

export default function ConfirmLenders({compensate, onNext, onBack}) {

  const [addAnotherVehicle, setAddAnotherVehicle] = useState(compensate.addAnotherVehicle)

  const [addAnotherVehicleError, setAddAnotherVehicleError] = useState(false)


  return (<>
    <div className='d-flex justify-content-center align-items-center heading-icon fdc-form'>
      <img src='/icons/TyreMoney.png' alt='icon1' />
      <h2 className='title'>Great News! You have <span className='ycm-orange'>{compensate?.claims?.length}</span> potential {compensate?.claims?.length > 1 ? 'claims' : 'claim'} and
      you could be owed <span className='ycm-orange'>£{(compensate?.claims?.length * claimValue).toLocaleString()}*</span></h2>
    </div>
    <div className='fdc-form gif-input'>
      <div className='row'>
        <div className='col-md-12'>
          <div className='form-block'>
            {compensate?.claims?.map((claims, i) => (
              <div key={i} className='row'>
                <div key={i} className='lender-block'>
                {claims?.lender_name}
                {claims?.date_finance_was_taken && <p className='mb-0'>Finance Start Date: {claims?.date_finance_was_taken }</p>}
                {claims?.make && <p className='mb-0'>Vehicle Make</p>}
                {claims?.make} {claims?.make && <span>-</span>} {claims?.new_or_used}  {claims?.vehicleRegistrationNumber 
                  && <>{<br />} Reg - {claims?.vehicleRegistrationNumber} {<br />} Year: {claims?.vehicleMakeYear}</>}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className='col-md-12'>
          <div className='form-block'>
            <Label  style={{color: '#008f8f'}} label={<>Add another car to claim for a further <span className='ycm-orange'>£{claimValue.toLocaleString()}*</span></>} />
            <ListRadioOptions
              label={'Have you taken out any additional car finance between April 2007 to January 2021 that has not been listed above?'}
              items={[{ text: "Yes", value: true }, { text: "No", value: false }]}
              onSelect={setAddAnotherVehicle}
              value={addAnotherVehicle}
              className="d-inline-block"
              divStyle="full-width"
              id={'addAnotherVehicle'}
            />
            {addAnotherVehicleError ?
              <div>
                <span className="text-danger">
                    <p>Please select an option</p>
                </span>
              </div>
              :<></>
            }
          </div>
        </div>
        <div className='col-md-6 mx-auto text-center'>
          <FormActionButton
            onNext={() => {
              if(addAnotherVehicle !== true && addAnotherVehicle !== false){setAddAnotherVehicleError(true)}
              if(addAnotherVehicle === true || addAnotherVehicle === false){
                onNext({},(addAnotherVehicle === true ? formPages.RequestFinanceLender : formPages.Summary))
              }
            }}
            onBack={() => {
              onBack(formPages.RequestFinanceLender)
            }}
          />
          <br />
          <p className='terms'> *based on an average refund of hidden commissions of £1,100, plus 8% annual compensatory interest</p>
        </div>
      </div>
    </div>
  </>)
}
