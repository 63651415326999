import axios from "axios";
import { reviewSubmission } from "../containers/CarFinance/mapper";

const pages = {
  IntroCheckDetails: 0,
  NewClaimantDetails: 0,
  AddressDetails: 0,
  AddressLookup: 0,
  ContactDetails: 0,
  RegistrationDetails: 0,
  RegistrationResults: 0,
  PreviousAddress: 0,
  Signature: 0,
  CreditCheckSuccess: 0,
  RequestFinanceLender: 0,
  ConfirmLenders: 0,
  Summary: 0,
  Success: 0,
  NoClaim: 0,
  Error: 0
}

export const lender = [
  { value: "Black Horse", text: "Black Horse" },
  { value: "Close Brothers", text: "Close Brothers" },
  { value: "Mobilize", text: "Mobilize" },
  { value: "MotoNovo", text: "MotoNovo" },
  { value: "Vauxhall Finance", text: "Vauxhall Finance" },
  { value: "BMW Financial Services (inc Mini)", text: "BMW Financial Services (inc Mini)" },
  { value: "Volkswagen Financial Services", text: "Volkswagen Financial Services" },
  { value: "Ford Credit Europe (FCE)", text: "Ford Credit Europe (FCE)" },
  { value: "Ford Financial Services", text: "Ford Financial Services" },
  { value: "Mercedes-Benz Financial Services", text: "Mercedes-Benz Financial Services" },
  { value: "Northridge Finance", text: "Northridge Finance" },
  { value: "Santander Consumer Finance (UK) Plc", text: "Santander Consumer Finance (UK) Plc" },
  { value: "Stellantis", text: "Stellantis" },
  { value: "AA Financial Services", text: "AA Financial Services" },
  { value: "ALD Automotive", text: "ALD Automotive" },
  { value: "Aldermore", text: "Aldermore" },
  { value: "Alphera Financial Services", text: "Alphera Financial Services" },
  { value: "Audi (VW Financial Services)", text: "Audi (VW Financial Services)" },
  { value: "Bamboo", text: "Bamboo" },
  { value: "Barclays Partner Finance", text: "Barclays Partner Finance" },
  { value: "Bentley", text: "Bentley" },
  { value: "Blue Motor Finance", text: "Blue Motor Finance" },
  { value: "Borderway Finance Limited", text: "Borderway Finance Limited" },
  { value: "CA Auto Finance", text: "CA Auto Finance" },
  { value: "Car Cash Point", text: "Car Cash Point" },
  { value: "Car Finance 247", text: "Car Finance 247" },
  { value: "Car Money", text: "Car Money" },
  { value: "Creation", text: "Creation" },
  { value: "Credo Capital Finance Ltd", text: "Credo Capital Finance Ltd" },
  { value: "Dalbeattie Finance Co Limited", text: "Dalbeattie Finance Co Limited" },
  { value: "FCA Automotive Services UK Ltd", text: "FCA Automotive Services UK Ltd" },
  { value: "Finio Loans", text: "Finio Loans" },
  { value: "GMAC", text: "GMAC" },
  { value: "Hampshire Trust PLC", text: "Hampshire Trust PLC" },
  { value: "Hartwell Finance Limited", text: "Hartwell Finance Limited" },
  { value: "Hitachi Capital (Novuna)", text: "Hitachi Capital (Novuna)" },
  { value: "Honda Financial Services", text: "Honda Financial Services" },
  { value: "Hyundai Finance", text: "Hyundai Finance" },
  { value: "Ignition Credit Plc", text: "Ignition Credit Plc" },
  { value: "JBR Capital", text: "JBR Capital" },
  { value: "JCB Finance Ltd", text: "JCB Finance Ltd" },
  { value: "Kia Finance", text: "Kia Finance" },
  { value: "Loan On Your Car", text: "Loan On Your Car" },
  { value: "Lombard Asset Finance", text: "Lombard Asset Finance" },
  { value: "Mann Island Finance", text: "Mann Island Finance" },
  { value: "Marsh Finance", text: "Marsh Finance" },
  { value: "Mitsubishi HC Capital UK Plc", text: "Mitsubishi HC Capital UK Plc" },
  { value: "Moneyway", text: "Moneyway" },
  { value: "Motor Kitty", text: "Motor Kitty" },
  { value: "Nissan Motor Manufacturing UK Ltd", text: "Nissan Motor Manufacturing UK Ltd" },
  { value: "Novuna", text: "Novuna" },
  { value: "Paragon", text: "Paragon" },
  { value: "PCF Bank / Asset Link Capital", text: "PCF Bank / Asset Link Capital" },
  { value: "Porsche", text: "Porsche" },
  { value: "PSA", text: "PSA" },
  { value: "RCI", text: "RCI" },
  { value: "SEAT", text: "SEAT" },
  { value: "Shawbrook Bank Limited", text: "Shawbrook Bank Limited" },
  { value: "Skoda", text: "Skoda" },
  { value: "Startline Motor Finance", text: "Startline Motor Finance" },
  { value: "Toyota Financial Services", text: "Toyota Financial Services" },
  { value: "Volkswagen", text: "Volkswagen" },
  { value: "Zopa", text: "Zopa" },
  { value: "Ferrari Financial Services", text: "Ferrari Financial Services" },
  { value: "BNP Paribas", text: "BNP Paribas" },
  { value: "KTM London", text: "KTM London" },
  { value: "Tesla Financial Services Limited", text: "Tesla Financial Services Limited" },
  { value: "SAIC Maxus", text: "SAIC Maxus" },
  { value: "Superbike Loans", text: "Superbike Loans" }
];


export const carMakes = [
  { value: "Ford", text: "Ford" },
  { value: "Volkswagen", text: "Volkswagen" },
  { value: "Audi", text: "Audi" },
  { value: "Vauxhall", text: "Vauxhall" },
  { value: "BMW", text: "BMW" },
  { value: "Mini", text: "Mini" },
  { value: "Toyota", text: "Toyota" },
  { value: "Kia", text: "Kia" },
  { value: "Mercedes Benz", text: "Mercedes Benz" },
  { value: "Hyundai", text: "Hyundai" },
  { value: "Nissan", text: "Nissan" },
  { value: "Adria", text: "Adria" },
  { value: "Alfa Romeo", text: "Alfa Romeo" },
  { value: "Auto-Trail (Fiat)", text: "Auto-Trail (Fiat)" },
  { value: "Bailey", text: "Bailey" },
  { value: "Benimar", text: "Benimar" },
  { value: "Bentley", text: "Bentley" },
  { value: "Carado", text: "Carado" },
  { value: "Chevrolet", text: "Chevrolet" },
  { value: "Chrysler", text: "Chrysler" },
  { value: "Citroen/DS", text: "Citroen/DS" },
  { value: "Compass", text: "Compass" },
  { value: "Cupra", text: "Cupra" },
  { value: "Dacia", text: "Dacia" },
  { value: "Daewoo", text: "Daewoo" },
  { value: "Daihatsu", text: "Daihatsu" },
  { value: "Daimler", text: "Daimler" },
  { value: "Datsun", text: "Datsun" },
  { value: "Dodge", text: "Dodge" },
  { value: "Ducati", text: "Ducati" },
  { value: "Eldiss", text: "Eldiss" },
  { value: "Elnagh", text: "Elnagh" },
  { value: "Ferrari", text: "Ferrari" },
  { value: "Fiat", text: "Fiat" },
  { value: "GMC", text: "GMC" },
  { value: "Honda", text: "Honda" },
  { value: "Honda Motorcycles", text: "Honda Motorcycles" },
  { value: "Hummer", text: "Hummer" },
  { value: "Infiniti", text: "Infiniti" },
  { value: "Isuzu", text: "Isuzu" },
  { value: "Iveco", text: "Iveco" },
  { value: "Jaguar", text: "Jaguar" },
  { value: "Jeep", text: "Jeep" },
  { value: "Kawasaki", text: "Kawasaki" },
  { value: "KTM", text: "KTM" },
  { value: "Lamborghini", text: "Lamborghini" },
  { value: "LandRover", text: "Land Rover" },
  { value: "LDV", text: "LDV" },
  { value: "Lexmoto", text: "Lexmoto" },
  { value: "Lexus", text: "Lexus" },
  { value: "Lotus", text: "Lotus" },
  { value: "Man", text: "Man" },
  { value: "Maserrati", text: "Maserrati" },
  { value: "Maxus", text: "Maxus" },
  { value: "Mazda", text: "Mazda" },
  { value: "MG", text: "MG" },
  { value: "Mitsubishi", text: "Mitsubishi" },
  { value: "Peugeot", text: "Peugeot" },
  { value: "Polestar", text: "Polestar" },
  { value: "Porsche", text: "Porsche" },
  { value: "Proton", text: "Proton" },
  { value: "Renault", text: "Renault" },
  { value: "Roller Team", text: "Roller Team" },
  { value: "Rover", text: "Rover" },
  { value: "Royal Enfield", text: "Royal Enfield" },
  { value: "Saab", text: "Saab" },
  { value: "Seat", text: "Seat" },
  { value: "Skoda", text: "Skoda" },
  { value: "Smart", text: "Smart" },
  { value: "Ssangyong", text: "Ssangyong" },
  { value: "Subaru", text: "Subaru" },
  { value: "Suzuki", text: "Suzuki" },
  { value: "Swift", text: "Swift" },
  { value: "Tesla", text: "Tesla" },
  { value: "Triumph", text: "Triumph" },
  { value: "Volvo", text: "Volvo" },
  { value: "Yamaha", text: "Yamaha" }
];

export const claimValue = 2068

Object.keys(pages).forEach(
  (key, index) => (pages[key] = index)
);
export const formPages = pages;

const baseUrl = new URL(window.location.href).host.includes('localhost')
? 'https://dev.app.ycmcarfinanceclaims.co.uk'
: new URL(window.location.href).origin

  export const CRM_SUBMIT_ENDPOINT = () =>{
    return `${baseUrl}/api/create-case`;
  }

  export const ADDRESS_ENDPOINT = async(query, containerId = null) => {
    try {
      const response = await axios.post(`${baseUrl}/api/address-find`, {
        query: query,
        container: containerId
      })
      const items = response.data.message.response.data.Items;
      // Check if items array has exactly one item, return that item directly
      if (items && items.length === 1) {
        const refinedResponse = await axios.post(`${baseUrl}/api/address-find`, {
          query: items[0].Text,
          container: items[0].Id
        })
        return refinedResponse.data.message.response.data.Items
      }
      return items;
    } catch (error) {
      console.error('Error finding addresses:', error);
      throw error;
    }
  }

  export const ADDRESS_RETRIEVE = async(id) => {
    try {
      const response = await axios.post(`${baseUrl}/api/address-retrieve`, {
        id: id
      })
      return response.data.message.response.data.Items[0]
    } catch (error) {
      console.error('Error finding addresses:', error);
      throw error;
    }
  }

  export const CREDIT_CHECK = () => {
    return `${baseUrl}/api/credit-check`
  }

  export const CLIENT_DETAILS = async(uid) => {
    try {
      const response = await axios.post(`${baseUrl}/api/client-details`, {
        uid: uid
      })
      return response.data.message.response.data
    } catch (error) {
      console.error('Error fetching client details', error);
      throw error;
    }

  }

  export const VALIDATE_EMAIL = async(email) => {
    try {
      const response = await axios.post(`${baseUrl}/api/validate-email`,{
        email: email
      });
       return response.data.message?.response === 'Valid Email Address'
    } catch (error) {
      console.error('Error validating email', error);
      throw error;
    }
  }

  export const MAKES_LIST = async() => {
    try {
      const response = await axios.get(`${baseUrl}/api/makes-list`)
      const makeList = response.data.message.response.data
      return makeList?.map((item) => ({
          text: item,
          value: item
      }))
    } catch (error) {
      console.error('Error fetching make list', error);
      throw error;
    }
    
  }

  export const LENDER_LIST = async() => {
    try {
      const response = await axios.get(`${baseUrl}/api/lender-list`)
      const lenderList = response.data.message.response.data
      return lenderList?.map((item) => ({
          text: item,
          value: item
      }))
    } catch (error) {
      console.error('Error fetching lender list', error);
      throw error;
    }
  }

  export const HTML_TO_PDF = async(content) => {
    try {
      const response = await axios.post(`${baseUrl}/api/html2pdf`,{
        content: content
      })
      const html2Pdf = response.data.message.response.data
      return html2Pdf
    } catch (error) {
      console.error('Error fetching HTML to PDF', error);
      throw error
    }
  }

  export const CUSTOMER_REVIEW = async(compensate, review) => {
    try {
      const response = await axios.post('https://datastore.tanantech.co.uk/1.0/data', reviewSubmission(compensate, review))
      console.log(response.data)
    } catch (error) {
      console.error('Error sending customer review')
      throw error
    }
  }

  export const REG_CHECK = () => {
    return `${baseUrl}/api/reg-check`
  }

  export const EMAIL_COMMS = () => {
    return `${baseUrl}/api/email-comms`
  }