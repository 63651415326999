import React, { useEffect, useState } from 'react'
import FormActionButton from '../shared-components/FormActionButton'
import { formPages, LENDER_LIST, MAKES_LIST } from '../../constants'
import ListRadioOptions from '../shared-components/ListRadioOptions'
import Select from '../shared-components/Select'
import Label from '../shared-components/Label'

export default function RequestFinanceLender({compensate, onNext}) {

  const [knowsLender, setKnowsLender] = useState()
  const [knowsVehicle, setKnowsVehicle] = useState()
  const [newOrUsed, setNewOrUsed] = useState()
  const [defendant, setDefendant] = useState()
  const [make, setMake] = useState()
  const [lenderList, setLenderList] = useState([])
  const [makesList, setMakesList] = useState([])

  const [knowsLenderError,setKnowsLenderError] = useState(false)
  const [knowsVehicleError, setKnowsVehicleError] = useState(false)
  const [defendantError, setDefendantError] = useState(false)
  const [makeError, setMakeError] = useState(false)
  const [newOrUsedError, setNewOrUsedError] = useState(false)

useEffect(() => {
  try {
    const fetchList = async() => {
      const fetchedMakes = await MAKES_LIST()
      setMakesList(fetchedMakes)
      const fecthedLenders = await LENDER_LIST()
      setLenderList(fecthedLenders)
    }
    fetchList()
  } catch (error) {
    console.error('error fetching lists', error)
    throw error
  }
},[])

  return (<>
    <div className='d-flex justify-content-center align-items-center heading-icon fdc-form'>
      <img src='/icons/TyreQuestion.png' alt='icon4' />
      {compensate.claims.length >= 1
       ? <h2 className='title'>Do you know who your car finance was with?</h2>
       : <h2 className='title'>Any additional information you can provide will help us locate your claim.</h2>}
    </div>
    <div className='fdc-form gif-input'>
      <div className='row'>
        <div className='col-md-12'>
          <div className='form-block'>
            {compensate.claims.length < 1 && <Label label={<><h2 className='title'>Do you know who your car finance was with?</h2></>} /> }
            <ListRadioOptions
              label={<div className='d-flex'><img className='light-bulb' src='/icons/Lightbulb-Tip.png' alt='light bulb'/>You might find this information on your bank
                statement or finance agreement if you have it</div>}
              items={[{ text: "Yes", value: true }, { text: "No", value: false }]}
              onSelect={(value) => {
                setKnowsLender(value)
                if(knowsLender || knowsLender === false){
                  setKnowsLenderError(false)
                }}}
              value={knowsLender}
              className="d-inline-block "
              divStyle="full-width mt-0"
              id={'knowsLender'}
            />
            {knowsLenderError ?
              <div>
                <span className="text-danger">
                    <p>Please select an option</p>
                </span>
              </div>
              :<></>
            }
          </div>
        </div>
        {knowsLender && <div className='col-md-12'>
          <div className='form-block'>
            <Select
              label={'Select the lender below'}
              options={[...lenderList]}
              value={defendant}
              onChange={(value) => {
                setDefendant(value)
                if(defendant){setDefendantError(false)}
              }}
              placeholder={'Select a lender'}
            />
            {defendantError ?
              <div>
                <span className="text-danger">
                    <p>Please select an option</p>
                </span>
              </div>
              :<></>
            }
          </div>
        </div>}
        {knowsLender === false && <> <div className='col-md-12'>
          <div className='form-block'>
            <Label label={<><h2 className='title'>Do you know the make of your vehicle?</h2></>} />
            <ListRadioOptions
              label={<div className='d-flex'><img className='light-bulb' src='/icons/Lightbulb-Tip.png' alt='light bulb'/>We can try to locate your lender based on the make of your car</div>}
              items={[{ text: "Yes", value: true }, { text: "No", value: false }]}
              onSelect={(value) => {
                setKnowsVehicle(value)
                if(knowsVehicle || knowsVehicle === false){
                  setKnowsVehicleError(false)
                }}}
              value={knowsVehicle}
              className="d-inline-block"
              divStyle="full-width mt-0"
              id={'vehicleMake'}
            />
            {knowsVehicleError ?
              <div>
                <span className="text-danger">
                    <p>Please select an option</p>
                </span>
              </div>
              :<></>
            }
          </div>
        </div>
        {knowsVehicle &&<> <div className='col-md-12'>
          <div className='form-block'>
            <Select
              label={'Select the make below'}
              options={[...makesList]}
              onChange={(value) => {
                setMake(value)
                if(make){setMakeError(false)}
              }}
              value={make}
              placeholder={'Select a make'}
            />
            {makeError ?
              <div>
                <span className="text-danger">
                    <p>Please select an option</p>
                </span>
              </div>
              :<></>
            }
          </div>
        </div>
        <div className='col-md-12'>
          <div className='form-block'>
            <ListRadioOptions
              label={<><h2 className='title'>Was your vehicle purchased new or used?</h2></>}
              items={[{ text: "New", value: 'New' }, { text: "Used", value: "Used" }]}
              onSelect={(value) => {
                setNewOrUsed(value)
                if(newOrUsed){
                  setNewOrUsedError(false)
                }}}
              value={newOrUsed}
              className="d-inline-block"
              divStyle="full-width"
              id={'newOrUsed'}
            />
            {newOrUsedError ?
              <div>
                <span className="text-danger">
                    <p>Please select an option</p>
                </span>
              </div>
              :<></>
            }
          </div>
        </div></>}
        </>}
        <div className='col-md-6 mx-auto text-center'>
          <FormActionButton
            onNext={() => {
              if(knowsLender !== true && knowsLender !== false){setKnowsLenderError(true)}
              if(knowsLender === false && knowsVehicle !== true && knowsVehicle !== false){setKnowsVehicleError(true)}
              if(knowsLender && !defendant){setDefendantError(true)}
              if(knowsLender === false && knowsVehicle === true && !make){setMakeError(true)}
              if(knowsLender === false && knowsVehicle === true && !newOrUsed){setNewOrUsedError(true)}
              if((knowsLender === true && defendant) || (knowsVehicle === true && make && newOrUsed)
                || (knowsLender === false && knowsVehicle === false)){
                onNext({
                  claims: (() => {
                    let updatedClaims = [...(compensate?.claims || [])];
               
                    // Handle defendant or make logic to add specific entries
                    if (defendant) {
                      updatedClaims.push({
                        lender_name: defendant,
                        finance_agreement_number: '',
                        date_finance_was_taken: '',
                        account_type: '',
                        make: '',
                        new_or_used: ''
                      });
                    } else if (make) {
                      updatedClaims.push({
                        lender_name: '',
                        finance_agreement_number: '',
                        date_finance_was_taken: '',
                        account_type: '',
                        make: make,
                        new_or_used: newOrUsed
                      });
                    }
                
                    return updatedClaims;
                  })()
                },(knowsLender === false && knowsVehicle === false && !compensate?.claims?.length && (!compensate.registrationDetails || compensate.registrationDetails.yearOfManufacture > 2020) ? formPages.NoClaim : knowsLender === false && knowsVehicle === false && compensate?.claims?.length ? formPages.Summary: formPages.ConfirmLenders))
              }
            }}
          />
          <br />
          <img className='light-bulb' src='/icons/Lightbulb-Tip.png' alt='light bulb'/><b className='tips'>You can claim for multiple vehicles</b>
        </div>
      </div>
    </div>
  </>)
}
