import FormActionButton from '../shared-components/FormActionButton'
import { claimValue, formPages } from '../../constants'
import ListRadioOptions from '../shared-components/ListRadioOptions'
import { useState } from 'react'

export default function RegistrationResults({compensate, onNext, onBack}) {

    const [newOrUsed, setNewOrUsed] = useState(compensate.purchasedRegCar)
    const [newOrUsedError, setNewOrUsedError] = useState(false)


  return (<>
    <div className='d-flex justify-content-center align-items-center heading-icon fdc-form'>
      <img src='/icons/TyreMoney.png' alt='icon1' />
      {compensate.registrationDetails.yearOfManufacture < 2021 
      ? <h2 className='title'>Great News! You're one step closer to making a claim, which could be worth more than <span className='ycm-orange'> £{claimValue.toLocaleString()}*</span></h2>
      : <h2 className='title'>Unfortunately only vehicles taken out on finance before 2021 are eligible. Don't worry we'll still check for other claims that might qualify. Simply press "Next" to move forward</h2> }
    </div>
    <div className='fdc-form'>
      <div className='row'>
        <div className='col-md-12'>
          <div className='box-message fw-semibold theme-color-1 text-center'>
            <h2 className='title' style={{fontWeight:'bolder'}}>{compensate.registrationDetails && "Vehicle Found"}</h2>
            <p> 
              Make: {compensate.registrationDetails?.make} <br />
             Year: {compensate.registrationDetails?.yearOfManufacture} <br /> 
             Colour: {compensate.registrationDetails?.colour} <br /> 
             Fuel Type: {compensate.registrationDetails?.fuelType}</p>
          </div>
        </div>
        {compensate.registrationDetails.yearOfManufacture < 2021 && <div className='col-md-12'>
          <div className='form-block'>
          <ListRadioOptions
              label={<><h2 className='title'>Was your vehicle purchased new or used?</h2></>}
              items={[{ text: "New", value: 'New' }, { text: "Used", value: "Used" }]}
              onSelect={(value) => {
                setNewOrUsed(value)
                if(newOrUsed !== false){
                  setNewOrUsedError(false)
                }}}
              value={newOrUsed}
              className="d-inline-block"
              divStyle="full-width"
              id={'newOrUsed'}
            />
            {newOrUsedError ?
              <div>
                <span className="text-danger">
                    <p>Please select an option</p>
                </span>
              </div>
              :<></>
            }
          </div>
        </div>}
        <div className='col-md-6 mx-auto text-center'>
          <FormActionButton
            onNext={() => {
              if (!newOrUsed){setNewOrUsedError(true)}
              if(newOrUsed || compensate.registrationDetails.yearOfManufacture > 2020){
                onNext({
                  purchasedRegCar: newOrUsed
                },(formPages.PreviousAddress))
              }
            }}
            onBack={() => {
              onBack(formPages.RegistrationDetails)
            }}
          />
          <br />
          <p className='terms'> *based on an average refund of hidden commissions of £1,100, plus 8% annual compensatory interest</p>
        </div>
      </div>
    </div>

  </>)
}
