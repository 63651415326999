import React from 'react'
import Header from '../components/website-components/Header/Header'
import Footer from '../components/website-components/Footer/Footer'

export default function PageLayout(props) {
  return (<>
    <Header />
        <div className='main'>
                {props.children}
        </div>
    <Footer />
  </>)
}
