
function clientObject (compensation){
    const clients = [
      {
        title: compensation.title,
        firstname: compensation.firstName.trim(),
        lastname: compensation.surname.trim(),
        previousName: compensation?.previousName?.trim(),
        dob: compensation.dob,
        email: {
          primary:  compensation?.email 
        },
        phone: {
          primary: compensation?.mobile?.trim()
        },
        clientdata: {
          uniqueID: compensation.uid,
          clientSignature: compensation.signature?.split('base64,')?.[1],
          onlineClaimSummaryPage: compensation?.base64Content
        },
        address: {
          line1: compensation.currentAddress?.Line1,
          line2: compensation.currentAddress?.Line2,
          city: compensation.currentAddress?.City,
          postcode: compensation.currentAddress?.PostalCode,
          county: compensation.currentAddress?.provinceName,
          country: compensation.currentAddress?.CountryName
        },
        previousAddresses: compensation?.previousAddress?.map((address) => ({
          line1: address.Line1 ? address.Line1 : address.line1,
          line2: address.Line2 ? address.Line2 : address.line2,
          line3: address.Line3 ? address.Line3 : address.line3,
          city: address.City ? address.City : address.city, 
          postcode: address.PostalCode ?? address.postcode,         
        }))
      }
    ];
    return clients;
  }

  /*function caseDataObject(compensation){
    const caseData ={

    }
    return caseData
  }*/

  //Case Incpetion
  export function getCaseInception (compensation) {

    const clients = clientObject(compensation)
//    const caseData = caseDataObject(compensation)

    return {
      clients: clients,
      caseData: {
        marketingChannel: compensation.marketingChannel,
        marketingCampaignName: compensation.marketingCampaignName,
        marketingAudience: compensation.marketingAudience,
        marketingCreative: compensation.marketingCreative
      },
      claims: compensation?.claims

    };
  }

  //Credit Check
  export function getCreditCheck (compensation) {
    const clients = clientObject(compensation)

    const currentAddress = {
      ...(compensation?.currentAddress?.BuildingNumber && { houseNumber: compensation.currentAddress.BuildingNumber }),
      ...(compensation?.currentAddress?.PostalCode && { postCode: compensation.currentAddress.PostalCode }),
      ...(compensation?.currentAddress?.City && { postTown: compensation.currentAddress.City }),
      ...(compensation?.currentAddress?.Street && { street: compensation.currentAddress.Street }),
      ...(compensation?.currentAddress?.SubBuilding && { flat: compensation.currentAddress.SubBuilding }),
      ...(compensation?.currentAddress?.BuildingName && { houseName: compensation.currentAddress.BuildingName }),
      ...(compensation?.currentAddress?.District && { district: compensation.currentAddress.District }),
      ...(compensation?.currentAddress?.ProvinceName && { county: compensation.currentAddress.ProvinceName }),
    };
      // Construct previousAddress dynamically if it exists
  const previousAddress = compensation?.previousAddress?.[0]
  ? {
      ...(compensation.previousAddress[0]?.BuildingNumber && { houseNumber: compensation.previousAddress[0].BuildingNumber }),
      ...(compensation.previousAddress[0]?.PostalCode && { postCode: compensation.previousAddress[0].PostalCode }),
      ...(compensation.previousAddress[0]?.City && { postTown: compensation.previousAddress[0].City }),
      ...(compensation.previousAddress[0]?.Street && { street: compensation.previousAddress[0].Street }),
      ...(compensation.previousAddress[0]?.SubBuilding && { flat: compensation.previousAddress[0].SubBuilding }),
      ...(compensation.previousAddress[0]?.BuildingName && { houseName: compensation.previousAddress[0].BuildingName }),
      ...(compensation.previousAddress[0]?.District && { district: compensation.previousAddress[0].District }),
      ...(compensation.previousAddress[0]?.ProvinceName && { county: compensation.previousAddress[0].ProvinceName }),
    }
  : undefined;

// Construct previousPreviousAddress dynamically if it exists
const previousPreviousAddress = compensation?.previousAddress?.[1]
  ? {
      ...(compensation.previousAddress[1]?.BuildingNumber && { houseNumber: compensation.previousAddress[1].BuildingNumber }),
      ...(compensation.previousAddress[1]?.PostalCode && { postCode: compensation.previousAddress[1].PostalCode }),
      ...(compensation.previousAddress[1]?.City && { postTown: compensation.previousAddress[1].City }),
      ...(compensation.previousAddress[1]?.Street && { street: compensation.previousAddress[1].Street }),
      ...(compensation.previousAddress[1]?.SubBuilding && { flat: compensation.previousAddress[1].SubBuilding }),
      ...(compensation.previousAddress[1]?.BuildingName && { houseName: compensation.previousAddress[1].BuildingName }),
      ...(compensation.previousAddress[1]?.District && { district: compensation.previousAddress[1].District }),
      ...(compensation.previousAddress[1]?.ProvinceName && { county: compensation.previousAddress[1].ProvinceName }),
    }
  : undefined;

    return {
      uid: compensation?.uid,
      title: clients[0].title,
      forename: clients[0].firstname,
      surname: clients[0].lastname,
      dateOfBirth: clients[0].dob,
      currentAddress,
      previousAddress,
      previousPreviousAddress,
    };
  }

  //Welcome Comms
  export function commsBotData(compensation,uuid){
    const clients = clientObject(compensation)
    return {
      template_name: "YCM_1,1c_Welcome",
      domain_index: "026",
      fields: {
          email: compensation?.email,
          firstname: clients[0].firstname,
          summary: compensation?.summaryContent,
          fingerprint: uuid,
          uniqueid: compensation?.uid
      }
  }
  }

  //Review submission to datastore
  export function reviewSubmission(compensation, review){
    return{
      web_form_id: 'motor_finance_customer_reviews',
      uniqueId: compensation?.uid,
      forename: compensation.firstName.trim(),
      lastname: compensation.surname.trim(),
      review: review
    }
  }