import { useState, useEffect } from 'react';
import { Form } from "react-bootstrap";
import { getDaysInMonth } from 'date-fns'; // Add getDaysInMonth function
import range from 'lodash/range';
import './DatePicker.css'


export default function DatePicker(props) {
    const { label, onChange, compensate, moreInfo } = props;

    const [day, setDay] = useState(compensate?.dob.split('/')[0]?.replace(/^0+/, '')  ?? '');
    const [month, setMonth] = useState((+compensate?.dob.split('/')[1]).toString() - 1 ?? '') 
    const [year, setYear] = useState(compensate?.dob.split('/')[2] ?? ''); 

    // Populate day options based on the selected month and year
    const daysInMonth = getDaysInMonth(new Date(year, month));
    const days = range(1, daysInMonth + 1);

    useEffect(() => {
      // Update the date in the state whenever any of the day, month, or year changes
      if (day && month !== '' && year !== '') {
        const formattedDate = formatDate(new Date(year, month, day));
        onChange(formattedDate); // Call the provided onChange function with the formatted date
      }
    }, [day, month, year, onChange]);


    const currentYear = new Date().getFullYear();
    const years = range(currentYear - 17, 1900, -1) 


    const months = [
      'JANUARY',
      'FEBRUARY',
      'MARCH',
      'APRIL',
      'MAY',
      'JUNE',
      'JULY',
      'AUGUST',
      'SEPTEMBER',
      'OCTOBER',
      'NOVEMBER',
      'DECEMBER',
    ];
    
  
    const formatDate = (date) => {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear().toString();
      return `${day}/${month}/${year}`;
    };
    
  
    const handleDayChange = (e) => {
      setDay(parseInt(e.target.value));
    };
  
    const handleMonthChange = (e) => {
      setMonth(parseInt(e.target.value));
    };
  
    const handleYearChange = (e) => {
      setYear(parseInt(e.target.value));
    };

    
    return (
        <>
            <Form.Label >{label}</Form.Label>
            <p>{moreInfo}</p>
            <div className="row g-0 d-flex justify-content-between">
            <div className="col-3">
              <select name="day" id="day" value={day} onChange={handleDayChange}>
                <option defaultValue="-" hidden>
                  day
                </option>
                {days.map((dayNumber) => (
                  <option key={dayNumber} value={dayNumber}>
                    {dayNumber}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-4">
              <select name="month" id="month" value={month} onChange={handleMonthChange}>
                <option defaultValue="-" hidden>
                  month
                </option>
                {months.map((monthName, index) => (
                  <option key={index} value={index}>
                    {monthName}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-3">
              <select name="year" id="year" value={year} onChange={handleYearChange}>
                <option defaultValue="-" hidden>
                  year
                </option>
                {years.map((yearNumber) => (
                  <option key={yearNumber} value={yearNumber}>
                    {yearNumber}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </>
    )
}