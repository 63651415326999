import React from 'react'
import './Header.css'

export default function Header() {
  return (
    <div className='header-wrapper'>
      <div className='container'>
        <div className="row g-0">
            <div className="site-logo d-flex align-items-center justify-content-between">
                <img src='/logos/Logo.png' alt='logo'/>
            </div>
        </div>
      </div>
    </div>
  )
}
