import React, { useState } from 'react'
import ListRadioOptions from '../shared-components/ListRadioOptions'
import FormActionButton from '../shared-components/FormActionButton'
import { ADDRESS_ENDPOINT, ADDRESS_RETRIEVE, formPages } from '../../constants'
import Text from '../shared-components/Text'
import ButtonComponent from '../shared-components/Button'
import Label from '../shared-components/Label'
import ReactSelect from '../shared-components/ReactSelect/ReactSelect'

export default function PreviousAddress({compensate, onNext, onBack}) {

  const [addressData2, setAddressData2] = useState(compensate.previousAddressData ?? null)
  const [addressData3, setAddressData3] = useState(compensate.previousPreviousAddressData ?? null)
  const [addressSelected2, setAddressSelected2] = useState(compensate.previousAddressSelected ?? '')
  const [addressSelected3, setAddressSelected3] = useState(compensate.previousPreviousAddressSelected ?? '')
  const [previousAddress, setPreviousAddress] = useState(compensate.previousAddress ?? [])
  const [finalAddress, setFinalAddress] = useState(compensate.finalAddress)
  const [over3Years, setOver3Years] = useState(compensate.over3Years)
  const [enterAddress2, setEnterAddress2] = useState(compensate.previousAddressPostCode ?? '')
  const [enterAddress3, setEnterAddress3] = useState(compensate.previousPreviousAddressPostCode ?? '')

  const [over3YearsError, setOver3YearsError] = useState(false)
  const [enterAddress2Error, setEnterAddress2Error] = useState(false);
  const [addressSelected2Error, setaddressSelected2Error] = useState(false);
  const [finalAddressError, setFinalAddressError] = useState(false)
  const [enterAddress3Error, setEnterAddress3Error] = useState(false);
  const [addressSelected3Error, setaddressSelected3Error] = useState(false);
  const [sameAddressError, setSameAddressError] = useState(false)


  const addressSearch = async (addressValue, setAddressDataCallback) => {
    const data = await ADDRESS_ENDPOINT(addressValue);
    setAddressDataCallback(data);
  };

  const generateSelectOptions = (addressData) => {
    return addressData?.map((item) => ({
      value: item.Id,
      text: item.Text,
      description: item.Description
    }));
  };

  const selectOptions = generateSelectOptions(addressData2);
  const selectOptions2 = generateSelectOptions(addressData3);

  const handleAddressChange = async (e, addressData, setAddressDataCallback, setSelectedCallback, enterAddress, index) => {
    const returnedContainerId = e.value;
    const selectedItem = addressData?.find(item => item.Id === returnedContainerId);
    if (selectedItem.Description) {
      const data = await ADDRESS_ENDPOINT(enterAddress, returnedContainerId);
      setAddressDataCallback(data);
    } else {
      const data = await ADDRESS_RETRIEVE(returnedContainerId);
      setPreviousAddress(prevAddresses => {
        const newAddresses = [...prevAddresses];
        newAddresses[index] = data;
        return newAddresses;
      });      setSelectedCallback(e);
    }
  };


  const removePreviousAddress1 = (over3Years) => {
    if (over3Years) {
      setPreviousAddress(prevAddresses => prevAddresses.filter((_, index) => index !== 0));
    }
  };

  const removePreviousAddress2 = (finalAddress) => {
    if (finalAddress === false) {
      setPreviousAddress(prevAddresses => prevAddresses.filter((_, index) => index !== 1));
    }
  };

  return (<>
    <div className='d-flex justify-content-center align-items-center heading-icon fdc-form'>
      <img src='/icons/TyreHouse.png' alt='icon2' />
      <h2 className='title'>To ensure we locate your finance details, we just need a bit more information from you.</h2>
    </div>
    <div className='fdc-form gif-input'>
        <div className='col-md-12'>
          <div className='form-block'>
            <ListRadioOptions 
              label={'Have you lived at your current address for more than 3 years?'}
              items={[{text: "Yes", value: true}, {text: "No", value: false}]}
              onSelect={(value) => {
                setOver3Years(value);
                removePreviousAddress1(value); 
                setOver3YearsError(false) 
              }}
              value={over3Years}
              className={'d-inline-block'}
              divStyle={'full-width'}
              id={'over3years'}
            />
            {over3YearsError ?
              <div>
                <span className="text-danger">
                    <p>Please select an option</p>
                </span>
              </div>
              :<></>
              }
          </div>
        </div>
        {over3Years === false && <>
          <div className='col-md-12'>  
            <div className='form-block mb-3'>
              <Label label={'Please provide your previous address details'}/>
            </div>
          </div>
          <div className="form-block row d-flex align-items-end">
            <div className="col-9 pe-0">
                <Text 
                    label={"Address Search"}
                    htmlFor="UK Address Search"
                    onChange={(e) => {
                      setEnterAddress2(e.target.value);
                      setEnterAddress2Error(false)
                    }}
                    id="UK Address Search"
                    moreInfo={"Enter your postcode and click search"}
                    value={enterAddress2}
                    placeholder={'Enter your postcode here'}
                />

            </div>
            <div className="col-3 px-0">
                <ButtonComponent
                    label={`Search`}
                    onClick={() => {if(enterAddress2.length > 1){addressSearch(enterAddress2, setAddressData2)}else{setEnterAddress2Error(true)}}}
                    style={{padding: '11px 15px', marginTop: '8px', minWidth:'0px', backgroundColor: '#008F8F', width: '100%'}}
                />
            </div>
            {enterAddress2Error ?
              <div>
                <span className="text-danger">
                    <p>Please enter your postcode</p>
                </span>
              </div>
              :<></>
            }
          </div>
          <div className='row'>
            <div className='col-md-12'>  
              <div className='form-block'>
                <ReactSelect
                  label={'Address Select'}
                  options={selectOptions}
                  placeholder={'Click here to select your address'}
                  onChange={(e) => {
                    handleAddressChange(e, addressData2, setAddressData2, setAddressSelected2, enterAddress2, 0);
                    setaddressSelected2Error(false)
                  }}
                  value={addressSelected2}
                />
                {addressSelected2Error ?
                  <div>
                    <span className="text-danger">
                        <p>Please select an address</p>
                    </span>
                  </div>
                  :<></>
                }
              </div>
            </div>
            <div className='col-md-12'>
              <div className='box-message fw-semibold theme-color-1 text-center'>
                {previousAddress[0]?.Company && <p className='mb-0'>{previousAddress[0]?.Company}</p>}
                {previousAddress[0]?.Line1 && <p className='mb-0'>{previousAddress[0]?.Line1}</p>}
                {previousAddress[0]?.Line2 && <p className='mb-0'>{previousAddress[0]?.Line2}</p>}
                {previousAddress[0]?.City && <p className='mb-0'>{previousAddress[0]?.City}</p>}
                {previousAddress[0]?.CountryName && <p className='mb-0'>{previousAddress[0]?.CountryName}</p>}
                {previousAddress[0]?.PostalCode && <p className='mb-0'>{previousAddress[0]?.PostalCode}</p>}
              </div>
            </div>
            <div className='col-md-12'>
              <div className='form-block'>
                <ListRadioOptions 
                  label={'Do you need to add another address?'}
                  items={[{text: "Yes", value: true}, {text: "No", value: false}]}
                  onSelect={(value) => {
                    setFinalAddress(value);
                    removePreviousAddress2(value);
                    setFinalAddressError(false)
                    }}
                  value={finalAddress}
                  className={'d-inline-block'}
                  divStyle={'full-width'}
                  id={'finalAddress'}
                />
                {finalAddressError ?
                  <div>
                    <span className="text-danger">
                        <p>Please select an option</p>
                    </span>
                  </div>
                  :<></>
                  }
              </div>
            </div>
            {finalAddress === true && <>
              <div className="form-block row d-flex align-items-end">
                <div className="col-9 pe-0">
                    <Text 
                        label={"Address Search"}
                        htmlFor="UK Address Search"
                        onChange={(e) => {
                          setEnterAddress3(e.target.value);
                          setEnterAddress3Error(false)
                        }}
                        id="UK Address Search"
                        moreInfo={"Enter your postcode and click search"}
                        value={enterAddress3}
                        placeholder={'Enter your postcode here'}
                    />
                
                </div>
                <div className="col-3 px-0">
                    <ButtonComponent
                        label={`Search`}
                        onClick={() => {if(enterAddress3.length > 1){addressSearch(enterAddress3, setAddressData3)}else{setEnterAddress3Error(true)}}}
                        style={{padding: '11px 15px', marginTop: '8px', minWidth:'0px', backgroundColor: '#008F8F', width: '100%'}}
                    />
                </div>
                  {enterAddress3Error ?
                    <div>
                      <span className="text-danger">
                          <p>Please enter your postcode</p>
                      </span>
                    </div>
                    :<></>
                  }
              </div>
              <div className='col-md-12'>  
                <div className='form-block'>
                  <ReactSelect
                    label={'Address Select'}
                    options={selectOptions2}
                    placeholder={'Click here to select your address'}
                    onChange={(e) => {
                      handleAddressChange(e, addressData3, setAddressData3, setAddressSelected3, enterAddress3, 1);
                      setaddressSelected3Error(false);
                      if(previousAddress[0]?.Id !== previousAddress[1]?.Id){setSameAddressError(false)};
                    }}
                    value={addressSelected3}
                  />
                  {addressSelected3Error ?
                    <div>
                      <span className="text-danger">
                          <p>Please select an address</p>
                      </span>
                    </div>
                    :<></>
                  }
                  {sameAddressError ?  <div>
                      <span className="text-danger">
                          <p>This address has already been provided</p>
                      </span>
                    </div>
                    :<></>}
                </div>
              </div>
              <div className='col-md-12'>
                <div className='box-message fw-semibold theme-color-1 text-center'>
                  {previousAddress[1]?.Company && <p className='mb-0'>{previousAddress[1]?.Company}</p>}
                  {previousAddress[1]?.Line1 && <p className='mb-0'>{previousAddress[1]?.Line1}</p>}
                  {previousAddress[1]?.Line2 && <p className='mb-0'>{previousAddress[1]?.Line2}</p>}
                  {previousAddress[1]?.City && <p className='mb-0'>{previousAddress[1]?.City}</p>}
                  {previousAddress[1]?.CountryName && <p className='mb-0'>{previousAddress[1]?.CountryName}</p>}
                  {previousAddress[1]?.PostalCode && <p className='mb-0'>{previousAddress[1]?.PostalCode}</p>}
                </div>
              </div>
            </>}
          </div>
        </>}
        <div className="col-md-6 text-center mx-auto">
          <FormActionButton 
            onNext={() =>{
              if(over3Years !== true && over3Years !== false){setOver3YearsError(true)}
              if(over3Years === false && !enterAddress2){setEnterAddress2Error(true)}
              if(over3Years === false && enterAddress2 && (!addressSelected2)){setaddressSelected2Error(true)}
              if(over3Years === false && finalAddress !== true && finalAddress !== false){setFinalAddressError(true)}
              if(finalAddress === true && !enterAddress3){setEnterAddress3Error(true)}
              if(finalAddress === true && enterAddress3 && !addressSelected3){setaddressSelected3Error(true)}
              if(previousAddress[0]?.Id === previousAddress[1]?.Id){setSameAddressError(true)}
              if(over3Years === true || (addressSelected2 && finalAddress === false) 
                || (previousAddress && addressSelected2 && addressSelected3 && previousAddress[0]?.Id !== previousAddress[1]?.Id) ){
                onNext({
                previousAddress: previousAddress,
                previousAddressPostCode: enterAddress2,
                previousAddressData: addressData2,
                previousAddressSelected: addressSelected2,
                previousPreviousAddressPostCode: enterAddress3,
                previousPreviousAddressData: addressData3,
                previousPreviousAddressSelected: addressSelected3,
                finalAddress,
                over3Years
                },(formPages.Signature))
              }
            }}
            onBack={() =>{
              onBack(compensate?.hasRegistration ? formPages.RegistrationResults : formPages.RegistrationDetails)
            }}
          />
        </div>
      </div>
  </>)
}
